<template>
  <div
    class="today-link cursor-pointer text-overline text-underline text-uppercase"
    :class="classes"
    @click="today"
  >
    Today
  </div>
</template>

<script>
import Vue from 'vue';
import { isSameDay } from 'date-fns';
import { mapGetters, mapMutations } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapGetters(['activeDate']),
    classes() {
      return {
        inactive: isSameDay(this.activeDate, new Date()),
      };
    },
  },
  methods: {
    ...mapMutations(['setActiveDate']),
    today() {
      this.setActiveDate(new Date());
    },
  },
});
</script>
