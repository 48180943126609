<template>
  <QScrollArea class="column col-grow" :horizontal="!personId" @scroll="scroll">
    <Toolbar :people="busyPeople" :person="personId && person" />

    <transition :name="`slide-${direction}`" tag="div" mode="out-in">
      <ErrorState v-if="error" @retry="refresh" />
      <div
        v-else
        :key="weekly ? weekNumber : dayNumber"
        v-touch-swipe.horizontal="swipe"
        :class="wrapperClasses"
      >
        <template v-if="personId">
          <component
            :is="dayComponent"
            v-for="agenda in schedule"
            :key="agenda.id"
            v-bind="agenda"
          />
        </template>
        <template v-else-if="busyPeople.length">
          <div
            v-for="person in busyPeople"
            :key="person.id"
            class="flex no-wrap"
          >
            <div class="flex no-wrap">
              <div class="sticky flex items-center">
                <QSkeleton
                  v-if="loading && !updated"
                  animation="blink"
                  size="md"
                  type="QAvatar"
                />
                <router-link
                  v-else
                  :to="{
                    name: 'calendar_person',
                    params: { personId: person.id },
                  }"
                >
                  <Avatar :person="person" size="xl" />
                </router-link>
                <div
                  v-if="scrolled > 0"
                  class="q-layout__shadow absolute-full overflow-hidden no-pointer-events"
                />
              </div>
              <component
                :is="dayComponent"
                class="flex no-wrap"
                v-bind="person.agenda"
              />
            </div>
          </div>
        </template>
        <EmptyState v-else />
      </div>
    </transition>

    <Fab
      @click="
        openSlot('new', {
          date: formattedActiveDate,
          personId: $route.params.personId,
        })
      "
    />
  </QScrollArea>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import {
  addBusinessDays,
  format,
  startOfWeek,
  getWeek,
  getDayOfYear,
} from 'date-fns';

import Day from '@/components/calendar/Day.vue';
import DayLoading from '@/components/calendar/DayLoading.vue';
import Dater from '@/components/calendar/Dater.vue';
import Toolbar from '@/components/calendar/Toolbar.vue';
import Today from '@/components/calendar/Today.vue';
import EmptyState from '@/components/layout/EmptyState.vue';
import ErrorState from '@/components/layout/ErrorState.vue';
import Fab from '@/components/layout/Fab.vue';
import Avatar from '@/components/people/Avatar.vue';

import {
  PeopleAgenda,
  PersonCalBase,
  PersonCalFull,
  AgendaUpdated,
} from '@graphql/queries/slots.gql';

import { EventBus } from '@/event-bus';
import date from '@/mixins/date';

export default Vue.extend({
  name: 'Calendar',
  apollo: {
    people: () => ({
      query: PeopleAgenda,
      fetchPolicy: 'cache-and-network',
      subscribeToMore: {
        document: AgendaUpdated,
        updateQuery(data, { subscriptionData }) {
          const updated = subscriptionData.data.agendaUpdated;
          const [personId] = updated.id.split(':');

          const person = data.people.find((person) => personId === person.id);

          if (person) {
            person.agenda = updated;
          }

          return data;
        },
        variables() {
          return this.peopleVariables;
        },
      },
      variables() {
        return this.peopleVariables;
      },
      result(res) {
        this.error = res.error;
      },
    }),
    person: () => ({
      query() {
        return this.full ? PersonCalFull : PersonCalBase;
      },
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.personId;
      },
      subscribeToMore: {
        document: AgendaUpdated,
        updateQuery(data, { subscriptionData, variables }) {
          const updated = subscriptionData.data.agendaUpdated;
          const [personId, date] = updated.id.split(':');

          if (personId !== variables.id) {
            return data;
          }

          data.person.schedule = data.person.schedule.map((day) =>
            date === day.date ? updated : day,
          );

          return data;
        },
        variables() {
          return {
            personId: this.personVariables.id,
            date: this.weekly ? undefined : this.personVariables.from,
          };
        },
      },
      variables() {
        return this.personVariables;
      },
      result(res) {
        this.error = res.error;
      },
    }),
  },
  components: {
    Avatar,
    Dater,
    Day,
    DayLoading,
    EmptyState,
    ErrorState,
    Fab,
    Today,
    Toolbar,
  },
  mixins: [date],
  inject: ['openSlot'],
  props: {
    personId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      error: null,
      scrolled: false,
    };
  },
  computed: {
    ...mapGetters(['direction', 'weeklyView']),
    busyPeople() {
      if (this.loading && !this.updated) {
        return Array.from({ length: 7 }, (_, i) => ({
          id: 4 - i,
          firstName: 'Loading',
          lastName: '',
        }));
      }

      return (this.people || [])
        .filter((person) => person.agenda.slots.length)
        .map((person) => ({
          ...person,
          agenda: {
            day: person.agenda,
            personId: person.id,
          },
        }));
    },
    dayComponent() {
      return this.loading && !this.updated ? DayLoading : Day;
    },
    dayNumber() {
      return getDayOfYear(this.activeDate);
    },
    full() {
      return this.personId && !(this.weekly && this.$q.screen.lt.md);
    },
    loading() {
      return this.$apolloData.loading;
    },
    peopleVariables() {
      return {
        date: this.formattedActiveDate,
      };
    },
    personVariables() {
      const from = this.weekly
        ? startOfWeek(this.activeDate, { weekStartsOn: 1 })
        : this.activeDate;

      return {
        id: this.personId,
        from: format(from, 'yyyy-MM-dd'),
        to: format(this.weekly ? addBusinessDays(from, 4) : from, 'yyyy-MM-dd'),
      };
    },
    schedule() {
      if (this.loading && !this.updated) {
        return Array.from({ length: this.weekly ? 5 : 1 }, (_, i) => ({
          id: i,
          full: this.full,
        }));
      }

      return this.person.schedule.map((day) => ({
        day: day,
        id: day.date,
        personId: this.personId,
        full: this.full,
      }));
    },
    swipe() {
      if (!this.personId) {
        return null;
      }

      return ({ direction }) => {
        this[`setDate${direction === 'right' ? 'Prev' : 'Next'}`](
          this.weekly ? 'week' : 'day',
        );
      };
    },
    updated() {
      const isPerson = !!this.personId;
      return isPerson
        ? this.person &&
            this.person.id === this.personId &&
            this.person.schedule?.[0]?.date === this.personVariables.from
        : this.people &&
            this.people?.[0]?.agenda?.date === this.peopleVariables.date;
    },
    weekly() {
      return this.personId && (this.$q.screen.gt.sm || this.weeklyView);
    },
    weekNumber() {
      return getWeek(this.activeDate);
    },
    wrapperClasses() {
      if (this.personId) {
        return {
          'calendar--person': true,
          [this.weekly ? 'calendar--weekly' : 'calendar--daily']: true,
          'q-px-sm': this.$q.screen.lt.md,
          'q-px-md': this.$q.screen.gt.sm,
          'q-py-xs': this.$q.screen.lt.md,
          'q-pb-sm': this.$q.screen.gt.sm,
          flex: this.weekly,
          'no-wrap': this.weekly,
          scroll: this.weekly && this.$q.screen.gt.sm,
        };
      }

      return {
        'calendar--people': true,
        'q-gutter-y-sm': true,
      };
    },
  },
  created() {
    EventBus.$on('task-deleted', this.refresh);
  },
  beforeDestroy() {
    EventBus.$off('task-deleted', this.refresh);
  },
  methods: {
    ...mapMutations(['setDateNext', 'setDatePrev']),
    refresh() {
      this.$apollo.queries.people.refetch();
      this.$apollo.queries.person.refetch();
    },
    scroll({ horizontalPercentage }) {
      this.scrolled = horizontalPercentage;
    },
  },
});
</script>

<style lang="scss">
.calendar--people {
  .sticky {
    background-color: #fff;
    left: 0;
    padding: $card-padding;
    padding-left: map-get($space-md, 'x');
    position: sticky;
    z-index: 2;

    .body--dark & {
      background-color: #18191b;
    }

    .q-layout__shadow {
      left: 10px;
      right: -10px;

      &::after {
        right: 10px;
      }
    }
  }
}

.calendar--person {
  max-width: $default-max-width;

  &.calendar--weekly {
    justify-content: space-between;

    .day {
      width: calc(20% - (#{$flex-gutter-sm} / 5 * 4));
    }
  }
}
</style>
