<template>
  <QSelect
    v-model="innerValue"
    v-bind="$attrs"
    hide-bottom-space
    rounded
    standout
    stack-label
    :label="label"
    :options="innerOptions"
    :rules="[(val) => !!val || 'Field is required']"
  />
</template>

<script>
import Vue from 'vue';

function map(value) {
  return {
    label: value.charAt(0).toUpperCase() + value.slice(1),
    value,
  };
}

export default Vue.extend({
  name: 'EnumField',
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    innerValue: {
      get() {
        return map(this.value);
      },
      set(option) {
        this.$emit('input', option.value);
      },
    },
    innerOptions() {
      return (this.options?.enumValues || []).map((p) => map(p.name));
    },
  },
});
</script>
