<template>
  <QPageSticky position="bottom-right" :offset="[18, 18]">
    <QBtn
      color="primary text-white text-uppercase"
      fab
      :icon="icon('plus')"
      text-color="primary"
      :to="to"
      @click="$emit('click')"
    />
  </QPageSticky>
</template>

<script>
import Vue from 'vue';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'Fab',
  mixins: [icons],
  props: {
    to: {
      type: Object,
      default: undefined,
    },
  },
});
</script>
