<template>
  <QItem
    class="company--card"
    :class="backgroundClasses"
    :style="{ '--company-rgb': companyRGB }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <QItemSection>
      <QItemLabel class="text-bold company">
        {{ company.name }}
      </QItemLabel>
    </QItemSection>
  </QItem>
</template>

<script>
import Vue from 'vue';

import color from '@/mixins/color';

export default Vue.extend({
  name: 'Company',
  mixins: [color],
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.company {
  &.q-item {
    min-height: 0;
  }
}
</style>
