<template>
  <QForm ref="form" @submit.prevent="submit">
    <NameField
      v-model="name"
      autofocus
      hide-bottom-space
      label="Create task"
      stack-label
    >
      <template #append>
        <QBtn
          color="primary"
          flat
          :icon="icon('check')"
          :loading="submitting"
          round
          size="sm"
          type="submit"
          @click="submit"
        />
      </template>
    </NameField>
  </QForm>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';

import NameField from '@/components/forms/NameField.vue';

import { CreateTask } from '@graphql/mutations/tasks.gql';

import { EventBus } from '@/event-bus';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'ProjectTaskForm',
  components: {
    NameField,
  },
  mixins: [icons],
  props: {
    project: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      submitting: false,
    };
  },
  methods: {
    async submit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      if (this.submitting) {
        return;
      }

      this.submitting = true;

      try {
        const result = await this.$apollo.mutate({
          mutation: CreateTask,
          variables: {
            fields: {
              name: this.name,
              projectId: this.project.id,
              status: this.status,
            },
          },
        });

        if (result.data.createTask) {
          this.name = '';

          EventBus.$emit('task-created', result.data.createTask);
        }
      } catch (e) {
        Sentry.captureException(e);
        this.$q.notify({
          type: 'negative',
          message: 'Cannot update task',
          caption: 'Something went wrong, please try again',
          timeout: 10000,
        });
      } finally {
        this.submitting = false;
      }
    },
  },
});
</script>
