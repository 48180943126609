<template>
  <section class="day">
    <div
      v-if="$q.screen.gt.sm"
      class="day__date q-pa-sm q-mb-sm"
      :class="classes"
    >
      <div class="text-uppercase">{{ dayOfWeek }}</div>
      <div>{{ dayOfMonth }}</div>
    </div>

    <Booking
      v-for="slot in day.slots"
      :key="slot.id"
      :full="full"
      :booking="slot"
      :day="date"
    />

    <Free
      v-if="day.free > 0"
      :duration="day.free"
      :query="{ date: isoStringDate(day.date), personId }"
    />
  </section>
</template>

<script>
import Vue from 'vue';
import { format, isSameDay, parseISO } from 'date-fns';

import Booking from '@/components/calendar/Booking.vue';
import Free from '@/components/calendar/Free.vue';

import date from '@/mixins/date';

export default Vue.extend({
  name: 'Day',
  components: {
    Booking,
    Free,
  },
  mixins: [date],
  props: {
    day: {
      type: Object,
      required: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    personId: {
      type: String,
      required: true,
    },
  },
  computed: {
    classes() {
      return {
        selected: this.selected,
        today: this.today,
      };
    },
    dayOfMonth() {
      return format(parseISO(this.day.date), 'dd');
    },
    dayOfWeek() {
      return format(parseISO(this.day.date), 'EEEEE');
    },
    date() {
      return new Date(this.day.date);
    },
    selected() {
      return isSameDay(this.date, this.activeDate);
    },
    today() {
      return isSameDay(this.date, new Date());
    },
  },
});
</script>

<style lang="scss">
.day {
  text-align: center;

  &__date {
    display: inline-block;
    font-size: 1.2em;
  }

  .slot {
    text-align: left;
  }

  .calendar--people &__date {
    display: none;
  }
}
</style>
