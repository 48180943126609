import { OAUTH_CLIENT_ID } from '@graphql/config';
import { init as initStore } from '@/store';
import { purgeCache } from '@/vue-apollo';

const REDIRECT_URI = `${window.origin}/login`;

export async function loggedIn() {
  return !!(await initStore()).getters.token;
}

export async function exchange(token: string) {
  const result = await fetch(`/auth/${token}`);
  const jsonResult = await result.json();

  (await initStore()).commit('setUser', jsonResult);
}

export function login(path: string | undefined = undefined) {
  const url = new URL(`https://accounts.google.com/o/oauth2/v2/auth`);
  url.searchParams.set('client_id', OAUTH_CLIENT_ID);
  url.searchParams.set('hd', '*');
  url.searchParams.set('prompt', 'select_account');
  url.searchParams.set('redirect_uri', REDIRECT_URI);
  url.searchParams.set('response_type', 'token');
  url.searchParams.set('scope', 'openid profile email');

  if (path) {
    url.searchParams.set('state', path);
  }

  window.location.href = url.href;
}

export async function logout() {
  await purgeCache();

  (await initStore()).commit('setUser', null);

  login();
}
