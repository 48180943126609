import { QuickScore, Result } from 'quick-score';

function isSimple<T extends SimpleKeys>(keys: Keys<T>): keys is T {
  return typeof keys[0] === 'string';
}

type SimpleKeys = readonly string[];
type WeightedKeys<T extends SimpleKeys> = {
  name: T[number];
  weight: number;
}[];

type Keys<T extends SimpleKeys> = T | WeightedKeys<T>;

function merge<T, K extends SimpleKeys>(
  keys: K,
  a: Result<T, K>,
  b: Result<T, K>,
): Result<T, K> {
  for (const key of keys) {
    const tk: K[number] = key;
    a.matches[tk].push(...b.matches[tk]);
    a.scores[tk] += b.scores[tk];
  }

  return a;
}

type ItemWithId<T> = T & {
  _key: number;
};

export class Searcher<T, K extends SimpleKeys> {
  private readonly keys: K;
  private readonly items: ItemWithId<T>[];
  private readonly scorer: QuickScore<ItemWithId<T>, K>;
  private readonly weights: { [P in K[number]]: number };

  constructor(items: T[], keys: Keys<K>) {
    let key = 0;

    this.items = items.map((item) => ({
      ...item,
      _key: key++,
    }));

    this.keys = isSimple(keys)
      ? keys
      : ((keys.map((k) => k.name) as unknown) as K);

    this.weights = Object.assign(
      {},
      ...this.keys.map((k) => ({
        [k]: isSimple(keys)
          ? 1
          : keys.find((key) => key.name === k)?.weight || 1,
      })),
    );

    this.scorer = new QuickScore<ItemWithId<T>, K>(this.items, {
      keys: this.keys,
    });
  }

  search(query: string) {
    const words = query.split(' ');
    const all = words.map((word) => this.scorer.search(word));
    const ids = all
      .map((results) => results.map((r) => r.item._key))
      .reduce((a, b) => a.filter((id) => b.includes(id)));

    const total = all.flat().reduce((total, result) => {
      if (!ids.includes(result.item._key)) {
        return total;
      }

      const old = total[result.item._key];

      if (!old) {
        total[result.item._key] = result;
      } else {
        merge(this.keys, old, result);
      }

      return total;
    }, {} as Record<number, Result<ItemWithId<T>, K>>);

    return Object.values(total)
      .map((result) => {
        const scores = Object.assign(
          {},
          ...(Object.keys(result.scores) as K[number][]).map((k) => ({
            [k]: result.scores[k] / words.length,
          })),
        );

        const score = Math.max(
          ...Object.entries<number>(scores).map(
            ([k, score]) => score * this.weights[k as K[number]],
          ),
        );

        const scoreKey = (Object.keys(result.scores) as K[number][]).find(
          (k) => result.scores[k] === score,
        );

        return {
          ...result,
          scores,
          score,
          scoreKey,
        };
      })
      .sort((a, b) => b.score - a.score);
  }
}
