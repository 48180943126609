<template>
  <QInput
    hide-bottom-space
    label="Delivery"
    rounded
    standout
    stack-label
    :value="value"
    @input="onInput"
  >
    <template #append>
      <QIcon :name="icon('calendarCheckOutline')" class="cursor-pointer">
        <QPopupProxy
          ref="qDateProxy"
          transition-show="scale"
          transition-hide="scale"
        >
          <QDate
            first-day-of-week="1"
            mask="YYYY-MM-DD"
            subtitle="Delivery date"
            today-btn
            :value="value"
            @input="onInput"
          />
        </QPopupProxy>
      </QIcon>
    </template>
  </QInput>
</template>

<script>
import Vue from 'vue';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'DeliveryField',
  mixins: [icons],
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
      this.$refs.qDateProxy.hide();
    },
  },
});
</script>
