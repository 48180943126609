<template>
  <QForm ref="form" class="form q-gutter-md" @submit.prevent="submit(false)">
    <div class="row no-wrap items-center" style="min-height: 40px">
      <div
        v-if="task && !editName"
        class="ellipsis text-h6 q-pr-sm"
        @click="editName = true"
      >
        {{ form.name }}
      </div>
      <NameField
        v-else-if="task"
        v-model="form.name"
        autofocus
        class="full-width q-pr-sm"
        dense
        hide-bottom-space
        @blur="submit(true)"
      />
      <div v-else class="text-h6">Create task</div>

      <QSpace />

      <slot name="controls" />
    </div>

    <NameField
      v-if="!task"
      v-model="form.name"
      autofocus
      counter
      label="Name *"
      stack-label
    />

    <Project
      v-if="task"
      avatars
      background
      clickable
      class="q-mb-md"
      :project="task.project"
      @click="openProject(task)"
    />

    <ProjectField v-else v-model="form.project" />

    <QField
      v-if="task && task.people.length"
      borderless
      class="q-mt-lg"
      label="Scheduled"
      stack-label
    >
      <template #control>
        <Avatar
          v-for="member in task.people.slice(0, 6)"
          :key="member.id"
          class="q-mr-xs"
          :person="member"
          size="38px"
        />
        <Avatar v-if="task.people.length > 6" size="xl">
          <template #empty> +{{ task.people.length - 6 }} </template>
        </Avatar>
      </template>
    </QField>

    <div class="form__multi flex items-start q-gutter-sm">
      <EnumField
        v-if="priorities"
        v-model="form.priority"
        class="form__select"
        label="Priority *"
        :options="priorities"
        :disable="!canEditPriority"
      />
      <EnumField
        v-if="statuses"
        v-model="form.status"
        class="form__select"
        label="Status *"
        :options="statuses"
      />
      <DeliveryField v-model="form.delivery" class="form__delivery" />
    </div>

    <NotesField v-model="form.notes" />

    <div class="form__bottom flex q-gutter-sm">
      <QBtn
        v-if="task"
        label="Delete task"
        color="negative"
        :loading="deleting"
        @click="deleteTask"
      />
      <QSpace v-else />
      <QBtn label="Save" type="submit" color="primary" :loading="submitting" />
    </div>
  </QForm>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { mapMutations, mapGetters } from 'vuex';

import Avatar from '@/components/people/Avatar.vue';
import Project from '@/components/projects/Project.vue';
import DeliveryField from '@/components/forms/DeliveryField.vue';
import EnumField from '@/components/forms/EnumField.vue';
import NameField from '@/components/forms/NameField.vue';
import NotesField from '@/components/forms/NotesField.vue';
import ProjectField, { toOption } from '@/components/forms/ProjectField.vue';

import { Priorities } from '@graphql/queries/priorities.gql';
import { Statuses } from '@graphql/queries/statuses.gql';
import { Task } from '@graphql/queries/tasks.gql';
import {
  CreateTask,
  DeleteTask,
  UpdateTask,
} from '@graphql/mutations/tasks.gql';

import { EventBus } from '@/event-bus';
import color from '@/mixins/color';
import { projectIdToParam } from '@/utils';

function initialValues(task, name = undefined) {
  if (task) {
    return Object.assign({}, task, {
      project: toOption(task.project),
    });
  } else {
    return {
      id: null,
      delivery: undefined,
      name,
      notes: undefined,
      priority: 'medium',
      project: undefined,
      status: 'working',
    };
  }
}

export default Vue.extend({
  name: 'TaskForm',
  apollo: {
    priorities: () => ({
      query: Priorities,
      fetchPolicy: 'cache-first',
    }),
    statuses: () => ({
      query: Statuses,
      fetchPolicy: 'cache-first',
    }),
    task: () => ({
      query: Task,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          id: this.realTaskId,
        };
      },
      skip() {
        return this.id === 'new';
      },
    }),
  },
  components: {
    Avatar,
    DeliveryField,
    EnumField,
    NameField,
    NotesField,
    Project,
    ProjectField,
  },
  mixins: [color],
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      deleting: false,
      editName: false,
      form: initialValues(undefined, this.name),
      initialLoad: false,
      submitting: false,
    };
  },
  computed: {
    ...mapGetters(['hasPendingForm', 'username']),
    canEditPriority() {
      return ['brancaleone', 'difilippo'].includes(this.username);
    },
    creating() {
      return this.id === 'new';
    },
    realTaskId() {
      return this.creating ? null : this.id;
    },
    prioritiesForSelect() {
      if (this.priorities) {
        return this.priorities.enumValues.map((p) => p.name);
      }
      return [];
    },
  },
  watch: {
    form: {
      handler: function () {
        if (this.initialLoad) {
          this.initialLoad = false;
          return;
        }

        if (!this.hasPendingForm) {
          this.setPendingForm(this.reset);
        }
      },
      deep: true,
    },
    task(task) {
      this.form = initialValues(task);
      this.initialLoad = true;
    },
  },
  methods: {
    ...mapMutations(['setPendingForm']),
    deleteTask() {
      this.deleting = true;

      this.$q
        .dialog({
          title: 'Are you sure?',
          message: `${this.task.name} will be gone forever.`,
          focus: 'none',
          ok: {
            color: 'negative',
          },
          cancel: {
            flat: true,
            textColor: 'grey-9',
          },
        })
        .onOk(async () => {
          try {
            const result = await this.$apollo.mutate({
              mutation: DeleteTask,
              variables: {
                id: this.task.id,
              },
            });

            if (result.data.deleteTask) {
              this.setPendingForm(null);
              EventBus.$emit('task-deleted', result.data.deleteTask);
            }
          } catch (e) {
            Sentry.captureException(e);
            this.$q.notify({
              type: 'negative',
              message: 'Cannot create task',
              caption: 'Something went wrong, please try again',
              timeout: 10000,
            });
          } finally {
            this.deleting = false;
          }
        })
        .onCancel(() => {
          this.deleting = false;
        });
    },
    openProject(task) {
      this.$router.push({
        name: 'company_project',
        params: {
          companyId: task.project.company.id,
          projectId: projectIdToParam(task.project.id),
        },
      });
    },
    reset(next) {
      this.$q
        .dialog({
          title: 'Are you sure?',
          message: 'Your changes will be gone forever!',
          focus: 'none',
          ok: {
            color: 'negative',
          },
          cancel: {
            flat: true,
            textColor: 'grey-9',
          },
        })
        .onOk(() => {
          if (this.task) {
            this.form = initialValues(this.task);
            this.initialLoad = true;
          }

          this.setPendingForm(null);
          next();
        });
    },
    async submit(nameOnly = false) {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      if (this.submitting) {
        return;
      }

      this.submitting = true;

      try {
        const result = await this.$apollo.mutate({
          mutation: this.form.id ? UpdateTask : CreateTask,
          variables: {
            id: this.form.empty ? null : this.form.id,
            fields: {
              name: this.form.name,
              ...(nameOnly
                ? {}
                : {
                    delivery: this.form.delivery || null,
                    notes: this.form.notes,
                    priority: this.form.priority,
                    projectId: this.form.project.id,
                    status: this.form.status,
                  }),
            },
          },
        });

        this.setPendingForm(null);

        if (result.data.updateTask) {
          if (nameOnly) {
            this.editName = false;
          } else {
            EventBus.$emit('task-updated', result.data.updateTask);
          }
        } else if (result.data.createTask) {
          EventBus.$emit('task-created', result.data.createTask);
        }
      } catch (e) {
        Sentry.captureException(e);
        this.$q.notify({
          type: 'negative',
          message: 'Cannot update task',
          caption: 'Something went wrong, please try again',
          timeout: 10000,
        });
      } finally {
        this.submitting = false;
      }
    },
  },
});
</script>
