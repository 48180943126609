<template>
  <QBanner v-if="!working" dense inline-actions class="bg-negative">
    <template #action>
      <QIcon :name="icon('cloudOffOutline')" />
    </template>
    {{ message }}
  </QBanner>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'StatusBar',
  mixins: [icons],
  computed: {
    ...mapGetters(['offline', 'working']),
    message() {
      return this.offline
        ? 'You are offline, changes will not be saved.'
        : "We're having some issues, trying to reconnect...";
    },
  },
});
</script>
