var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('QSelect',{ref:"select",attrs:{"label":("Project" + (_vm.validate ? ' *' : '')),"rules":_vm.validate ? [function (val) { return !!val || 'Field is required'; }] : [],"hide-bottom-space":"","hide-selected":_vm.hide,"rounded":"","stack-label":"","standout":"","use-input":"","options":_vm.options,"value":_vm.project},on:{"filter":_vm.projectsFilter,"popup-show":function($event){_vm.hide = true},"popup-hide":function($event){_vm.hide = false},"input":_vm.onInput},scopedSlots:_vm._u([{key:"selected-item",fn:function(ref){
var opt = ref.opt;
var itemProps = ref.itemProps;
var itemEvents = ref.itemEvents;
return [_c('Project',_vm._g(_vm._b({staticClass:"q-px-none q-pt-sm q-pb-xs",attrs:{"company":!_vm.projects,"project":opt.project}},'Project',itemProps,false),itemEvents))]}},{key:"option",fn:function(ref){
var opt = ref.opt;
var itemProps = ref.itemProps;
var itemEvents = ref.itemEvents;
return [_c('Project',_vm._g(_vm._b({key:opt.project.id,attrs:{"company":!_vm.projects,"project":opt.project}},'Project',itemProps,false),itemEvents))]}},{key:"no-option",fn:function(){return [_c('QItem',[_c('QItemSection',{staticClass:"text-grey"},[_vm._v("No results")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }