<template>
  <QInput
    label="Description"
    rounded
    standout
    stack-label
    type="textarea"
    :value="value"
    @input="onInput"
  />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'NotesField',
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
  },
});
</script>
