<template>
  <div :class="toolbarClasses">
    <Portal to="left" :disabled="$q.screen.lt.md">
      <Search label="Search tasks...">
        <div v-if="$q.screen.lt.md">
          <div class="filters flex q-gutter-y-sm q-gutter-x-sm q-px-md q-py-sm">
            <QSelect
              v-model="accountOption"
              label="Account"
              rounded
              stack-label
              standout
              :options="accountOptions"
            />
            <QSelect
              v-model="order"
              label="Order"
              rounded
              stack-label
              standout
              :options="orders"
            />
          </div>
          <QToggle
            class="q-px-md q-pt-xs text-grey-5"
            dense
            label="Show closed"
            name="show-closed"
            :value="taskClosed"
            @input="setTaskClosed"
          />
        </div>
        <PeopleList v-else :people="people" to="tasks_person" />
      </Search>
    </Portal>

    <div v-if="$q.screen.gt.sm">
      <div class="toolbar__inner">
        <PersonBack
          v-if="account && $q.screen.gt.sm"
          :person="account"
          :to="{ name: 'tasks' }"
        />
        <QSpace />
        <QToggle
          class="q-mr-md text-grey-5"
          label="Show closed"
          left-label
          :value="taskClosed"
          @input="setTaskClosed"
        />
        <div class="row items-baseline q-gutter-x-md">
          <div class="text-grey-5">Order by</div>
          <div
            v-for="{ label, value } in orders"
            :key="value"
            :class="actionClasses(value)"
            @click="order = { value }"
          >
            {{ label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';

import Search from '@/components/layout/Search.vue';
import PeopleList from '@/components/people/PeopleList.vue';
import PersonBack from '@/components/people/PersonBack.vue';

import { Accounts } from '@graphql/queries/accounts.gql';

import { personToOption, taskOrders } from '@/utils';

function addUnassigned(people) {
  return [
    ...(people || []),
    {
      id: 'unassigned',
      firstName: 'Unassigned',
      lastName: '',
    },
  ];
}

export default Vue.extend({
  name: 'TaskToolbar',
  apollo: {
    accounts: () => ({
      query: Accounts,
      fetchPolicy: 'cache-and-network',
    }),
  },
  components: {
    PeopleList,
    PersonBack,
    Search,
  },
  computed: {
    ...mapGetters(['taskClosed', 'taskOrder']),
    account() {
      return addUnassigned(this.accounts).find((a) => a.id === this.id);
    },
    accountOption: {
      get() {
        return this.id
          ? this.account && personToOption(this.account)
          : this.accountOptions[0];
      },
      set(option) {
        if (option.value == 'all') {
          return this.$router.push({
            name: 'tasks',
          });
        }

        this.$router.push({
          name: 'tasks_person',
          params: { personId: option.value },
        });
      },
    },
    accountOptions() {
      return [{ label: 'All', value: 'all' }].concat(
        this.people.map(personToOption),
      );
    },
    id() {
      return this.$route.params.personId;
    },
    orders() {
      return Object.keys(taskOrders).map((key) => ({
        label: taskOrders[key].label,
        value: key,
      }));
    },
    order: {
      get() {
        return this.orders.find((o) => o.value === this.taskOrder);
      },
      set(option) {
        this.setTaskOrder(option.value);
      },
    },
    people() {
      return addUnassigned(this.accounts);
    },
    toolbarClasses() {
      return {
        toolbar: true,
        'q-px-md': this.$q.screen.gt.sm,
        'q-pt-md': this.$q.screen.gt.sm,
        'q-pb-sm': true,
      };
    },
  },
  methods: {
    ...mapMutations(['setTaskClosed', 'setTaskOrder']),
    actionClasses(order) {
      return {
        'cursor-pointer': true,
        'text-overline': true,
        'text-uppercase': true,
        'text-underline': this.taskOrder === order,
      };
    },
  },
});
</script>
