var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('QSelect',_vm._b({ref:"select",attrs:{"label":"Task *","rules":[function (val) { return !!val || 'Field is required'; }],"hide-bottom-space":"","hide-selected":_vm.hide,"rounded":"","stack-label":"","standout":"","use-input":"","options":_vm.options,"value":_vm.task},on:{"filter":_vm.tasksFilter,"popup-show":function($event){_vm.hide = true},"popup-hide":function($event){_vm.hide = false},"input":_vm.onInput},scopedSlots:_vm._u([{key:"selected-item",fn:function(ref){
var opt = ref.opt;
var itemProps = ref.itemProps;
var itemEvents = ref.itemEvents;
return [_c('Task',_vm._g(_vm._b({staticClass:"q-px-none q-pt-sm q-pb-xs",attrs:{"task":opt.task}},'Task',itemProps,false),itemEvents))]}},{key:"option",fn:function(ref){
var opt = ref.opt;
var itemProps = ref.itemProps;
var itemEvents = ref.itemEvents;
return [_c('Task',_vm._g(_vm._b({key:opt.task.id,attrs:{"task":opt.task}},'Task',itemProps,false),itemEvents))]}},{key:"before-options",fn:function(){return [_c('QSeparator'),_c('NewTaskOption',{on:{"new-task":function($event){return _vm.$emit('new-task', _vm.input)}}})]},proxy:true},{key:"no-option",fn:function(){return [_c('NewTaskOption',{on:{"new-task":function($event){return _vm.$emit('new-task', _vm.input)}}}),_c('QItem',[_c('QItemSection',{staticClass:"text-grey"},[_vm._v("No results")])],1),_c('QSeparator')]},proxy:true}])},'QSelect',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }