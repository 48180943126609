<template>
  <QSelect
    v-model="innerValue"
    v-bind="$attrs"
    clearable
    :hide-selected="hide"
    :label="label"
    rounded
    stack-label
    standout
    use-input
    :options="options"
    @filter="onFilter"
    @popup-show="hide = true"
    @popup-hide="hide = false"
  >
    <template #option="scope">
      <QItem
        v-bind="scope.itemProps"
        :disable="disableOption(scope.opt)"
        v-on="scope.itemEvents"
      >
        <QItemSection>
          <QItemLabel
            >{{ scope.opt.label }}
            <QChip
              v-if="disableOption(scope.opt)"
              class="q-ml-sm text-bold"
              color="negative"
              text-color="white"
              size="sm"
            >
              Fully booked
            </QChip>
          </QItemLabel>
        </QItemSection>
      </QItem>
    </template>
  </QSelect>
</template>

<script>
import Vue from 'vue';

import { People, PeopleFree } from '@graphql/queries/people.gql';
import { Searcher } from '@/search';
import { personToOption } from '@/utils';

export default Vue.extend({
  name: 'PeopleSelect',
  apollo: {
    people: {
      query() {
        return this.free ? PeopleFree : People;
      },
      variables() {
        return this.free ? { date: this.free } : {};
      },
    },
  },
  props: {
    free: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      hide: false,
      options: [],
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value && this.value.id && personToOption(this.value);
      },
      set(option) {
        this.$emit(
          'input',
          (this.people || []).find((p) => p.id === option?.value),
        );
      },
    },
    items() {
      return Object.freeze(this.people || []);
    },
    searcher() {
      return new Searcher(this.items, ['firstName', 'lastName']);
    },
  },
  methods: {
    disableOption(opt) {
      return this.free && opt.person.agenda.free <= 0;
    },
    onFilter(val, update) {
      update(() => {
        this.input = val;

        if (!val.length) {
          this.options = Object.freeze(this.items.map(personToOption));
        } else {
          this.options = Object.freeze(
            this.searcher.search(val).map((r) => personToOption(r.item)),
          );
        }
      });
    },
  },
});
</script>
