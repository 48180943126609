<template>
  <section class="day">
    <div v-if="$q.screen.gt.sm" class="day__date q-pa-sm q-mb-sm">
      <div class="text-uppercase">
        <QSkeleton animation="blink" type="text" />
      </div>
      <div>
        <QSkeleton animation="blink" type="text" />
      </div>
    </div>

    <QSkeleton
      animation="blink"
      :class="`slot slot--${full ? 'full' : 'base'} duration-7`"
      type="rect"
    >
      <div class="slot__inner" />
    </QSkeleton>
  </section>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'DayLoading',
  props: {
    full: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
