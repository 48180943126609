export const DAY_DURATION = 7;

export const SENTRY_DSN =
  'https://a19ae1f9334045fdb0b2bdd1dd6e40f8@sentry.k8s.shaman.xyz/28';

export const OAUTH_CLIENT_ID =
  '71984133316-lp7dlqcab71h5mlmm96a1ijtk7vkd347.apps.googleusercontent.com';
export const OAUTH_CLIENT_SECRET = 'uRNT0M6yVVFcnaUOaZmnFDaE';
export const OAUTH_DOMAINS = [
  'shaman.xyz',
  'tribeadv.it',
  'tribecommunication.it',
];
