import {
  mdiAccountOutline,
  mdiArrowDown,
  mdiCalendarCheckOutline,
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiCloudOffOutline,
  mdiCogOutline,
  mdiContentCopy,
  mdiDeleteOutline,
  mdiEmailOutline,
  mdiExclamationThick,
  mdiExitToApp,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiHistory,
  mdiMinus,
  mdiOpenInNew,
  mdiPencil,
  mdiPhoneOutline,
  mdiPlus,
  mdiSwapHorizontal,
  mdiUpdate,
} from '@quasar/extras/mdi-v5';

const our = require.context('@/assets/icons', false, /\.svg$/);
const icons = {
  ...our.keys().reduce((m, i) => {
    const n = i.replace(/^\.\/(.+)\.svg$/, '$1');
    m[n] = `img:${our(i)}`;
    return m;
  }, {} as Record<string, string>),
  accountOutline: mdiAccountOutline,
  arrowDown: mdiArrowDown,
  calendarCheckOutline: mdiCalendarCheckOutline,
  check: mdiCheck,
  chevronLeft: mdiChevronLeft,
  chevronRight: mdiChevronRight,
  close: mdiClose,
  cloudOffOutline: mdiCloudOffOutline,
  cogOutline: mdiCogOutline,
  contentCopy: mdiContentCopy,
  deleteOutline: mdiDeleteOutline,
  email: mdiEmailOutline,
  exclamationThick: mdiExclamationThick,
  exitToApp: mdiExitToApp,
  eyeOffOutline: mdiEyeOffOutline,
  eyeOutline: mdiEyeOutline,
  history: mdiHistory,
  minus: mdiMinus,
  openInNew: mdiOpenInNew,
  pencil: mdiPencil,
  phone: mdiPhoneOutline,
  plus: mdiPlus,
  swapHorizontal: mdiSwapHorizontal,
  update: mdiUpdate,
};

export default {
  computed: {
    icon() {
      return (name: keyof typeof icons) => icons[name];
    },
  },
};
