<template>
  <div>
    <QBtn dense round unelevated @click="open = true">
      <Avatar color="grey-2" :person="value" :size="size" @click="open = true">
        <template #empty>
          <QIcon color="primary" :name="icon('pencil')" size="sm" />
        </template>
      </Avatar>
    </QBtn>

    <QDialog v-model="open">
      <QCard class="rgb text-center" :style="{ '--company-rgb': companyRGB }">
        <QCardSection class="text-h6 company">
          {{ project.name }}
        </QCardSection>

        <QCardSection class="q-py-none">
          <PeopleSelect v-model="innerValue" label="Project Leader" />
        </QCardSection>

        <QCardSection class="text-grey-5">
          You are about to change Project Leader of this project
        </QCardSection>

        <div class="q-pt-sm q-pb-md q-gutter-md">
          <QBtn label="Cancel" outline @click="open = false" />
          <QBtn label="Save" color="primary" @click="save" />
        </div>
      </QCard>
    </QDialog>
  </div>
</template>

<script>
import Vue from 'vue';

import Avatar from '@/components/people/Avatar.vue';
import PeopleSelect from '@/components/people/PeopleSelect.vue';

import { People } from '@graphql/queries/people.gql';
import { UpdateProject } from '@graphql/mutations/projects.gql';

import color from '@/mixins/color';
import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'AvatarField',
  apollo: {
    people: () => ({
      query: People,
      fetchPolicy: 'cache-and-network',
    }),
  },
  components: {
    Avatar,
    PeopleSelect,
  },
  mixins: [color, icons],
  props: {
    project: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: undefined,
    },
    value: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      open: false,
      innerValue: undefined,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.innerValue = value;
      },
      immediate: true,
    },
  },
  methods: {
    async save() {
      const result = await this.$apollo.mutate({
        mutation: UpdateProject,
        variables: {
          id: this.project.id,
          fields: {
            projectLeaderId: this.innerValue ? this.innerValue.id : null,
          },
        },
      });

      if (result.data.updateProject) {
        this.open = false;
        this.$emit('input', this.innerValue);
      }
    },
  },
});
</script>
