<template>
  <div class="q-py-md q-px-lg">
    <a
      v-for="group in groups"
      :key="group.id"
      class="block q-py-sm"
      :href="`#${group.id}`"
      @click.prevent="scroll(group)"
    >
      {{ group.name }}
    </a>
  </div>
</template>

<script>
import Vue from 'vue';
import { scroll } from 'quasar';
const { getScrollTarget } = scroll;

export default Vue.extend({
  name: 'Drawer',
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  methods: {
    scroll({ id }) {
      const el = document.querySelector(`#${id}`);

      if (!el) {
        return;
      }

      const target = getScrollTarget(el);
      const offset = el.offsetTop;

      target.scrollTo({
        top: offset - 8,
        behavior: 'smooth',
      });
    },
  },
});
</script>
