<template>
  <QFooter elevated>
    <Menu class="q-py-sm" />

    <StatusBar v-if="$q.screen.lt.md" />
  </QFooter>
</template>

<script>
import Vue from 'vue';

import Menu from '@/components/layout/Menu.vue';
import StatusBar from '@/components/layout/StatusBar.vue';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'Footer',
  components: {
    Menu,
    StatusBar,
  },
  mixins: [icons],
});
</script>

<style lang="scss">
.q-footer {
  &.q-layout__section--marginal {
    background-color: #fff;

    .body--dark & {
      background-color: $darkish;
    }
  }

  .q-tabs {
    padding-bottom: #{"max(env(safe-area-inset-bottom), #{map-get($space-sm, 'y')})"};
  }
}
</style>
