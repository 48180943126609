<template>
  <QItem>
    <QItemSection>
      <QItemLabel class="company text-bold">
        {{ project.company.name }}
      </QItemLabel>
      <QItemLabel>{{ project.name }}</QItemLabel>
    </QItemSection>
    <QItemSection avatar>
      <div class="flex items-center q-gutter-x-sm">
        <AvatarField
          :project="project"
          size="lg"
          :value="project.projectLeader"
        />
        <Avatar :person="project.account" size="lg" />
      </div>
    </QItemSection>
  </QItem>
</template>

<script>
import Vue from 'vue';

import Avatar from '@/components/people/Avatar.vue';
import AvatarField from '@/components/people/AvatarField.vue';

export default Vue.extend({
  name: 'ProjectHeader',
  components: {
    Avatar,
    AvatarField,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
});
</script>
