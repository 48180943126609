<template>
  <tr class="task__header--desktop" :style="style">
    <td class="text-bold" colspan="6">
      {{ task.project.company.name }}
    </td>
    <td class="task__status"></td>
  </tr>
</template>

<script>
import Vue from 'vue';

import task from '@/mixins/task';

export default Vue.extend({
  name: 'HeaderDesktop',
  mixins: [task],
});
</script>
