import Notify from 'quasar/src/plugins/Notify.js';;

import { init as initRouter } from '@/router';
import { init as initStore } from '@/store';

let lastCheck = +new Date();

export async function init() {
  const store = await initStore();
  const sw = 'serviceWorker' in navigator;

  // listen for updates available from register-service-worker
  store.watch(
    (state) => state.updating,
    (updating) => {
      if (updating) {
        Notify.create({
          message: 'Installing update',
          caption: sw
            ? 'Please wait a few seconds...'
            : 'Please refresh to use the new version',
          group: 'update',
          timeout: 20000,
        });
      }
    },
  );

  if (!sw) {
    return;
  }

  // listen for messages from the service worker
  navigator.serviceWorker.addEventListener('message', (e) => {
    const { kind } = e.data;
    switch (kind) {
      case 'update':
        const pending = store.getters.hasPendingForm;

        Notify.create({
          message: 'Update ready',
          caption: pending
            ? `Go will reload when you save or dismiss your edits`
            : 'Go is reloading...',
          group: 'update',
          timeout: 0,
        });

        if (pending) {
          store.watch(
            (state) => state.pendingForm,
            (form) => !form && window.location.reload(),
          );
        } else {
          window.location.reload();
        }

        break;
    }
  });

  // check for updates every 15 minutes
  (await initRouter()).beforeEach((to, from, next) => {
    next();

    if (!store.getters.swReg) {
      return;
    }

    if ((+new Date() - lastCheck) / 1000 < 15 * 60) {
      return;
    }

    store.getters.swReg.update();
    lastCheck = +new Date();
  });
}
