<template>
  <div class="q-slider--custom q-px-sm q-py-xs">
    <div v-if="max <= 0" class="q-slider__message text-h6">Fully booked</div>

    <div class="q-slider__marks flex justify-between">
      <span
        v-for="mark of marks"
        :key="mark"
        class="mark"
        :class="{ 'mark--inactive': mark > max }"
        >{{ mark }}</span
      >
    </div>

    <QCustomSlider
      markers
      :max="last"
      :max-value="max"
      :min="first"
      :step="1"
      :style="track"
      thumb-path="M 0, 10 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0"
      :value="value"
      @change="change"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { QSlider } from 'quasar';

// Custom QSlider implementing `maxValue`
const QCustomSlider = QSlider.extend({
  props: {
    maxValue: {
      type: Number,
      required: true,
    },
  },
  methods: {
    __updatePosition(event, dragging = this.dragging) {
      this.constructor.superOptions.methods.__updatePosition.call(
        this,
        event,
        dragging,
      );

      this.model = Math.min(this.model, this.maxValue);
      this.curRatio = Math.min(
        this.curRatio,
        (this.maxValue - this.min) / (this.max - this.min),
      );
    },
  },
});

import { DAY_DURATION } from '@graphql/config.ts';

export default Vue.extend({
  name: 'DurationField',
  components: {
    QCustomSlider,
  },
  props: {
    max: {
      type: Number,
      required: false,
      default: 1,
    },
    value: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  computed: {
    first() {
      return this.marks[0];
    },
    last() {
      return this.marks[this.marks.length - 1];
    },
    marks() {
      return Array(DAY_DURATION)
        .fill()
        .map((_, i) => i + 1);
    },
    ratio() {
      return ((this.max - this.first) / (this.last - this.first)) * 100;
    },
    track() {
      const colors = [];

      if (this.max <= 0) {
        colors.push(`#aaafb6 0%`);
      } else {
        colors.push(`#aaafb6 ${this.ratio}%`);
        colors.push(`#e7e9ec ${this.ratio}%`);
      }

      return {
        '--track-bg': `linear-gradient(to right, ${colors.join(', ')})`,
      };
    },
  },
  methods: {
    change(value) {
      this.$emit('input', value);
    },
  },
});
</script>

<style lang="scss">
.q-slider--custom {
  position: relative;

  .q-slider__marks {
    margin-left: -0.4em;
    margin-right: -0.4em;

    .mark {
      text-align: center;
      width: 1em;

      &--inactive {
        opacity: 0.25;
      }
    }
  }

  .q-slider__message {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    bottom: 0;
    color: $negative;
    display: flex;
    justify-content: center;
    left: -1em;
    position: absolute;
    right: -1em;
    text-align: center;
    top: 0;
    z-index: 1;

    .body--dark & {
      background-color: rgba($dark, 0.5);
    }
  }

  .q-slider__thumb {
    border-radius: 100%;
    box-shadow: $shadow-4;
    color: #fff;
    transform: translate3d(0, 0, 0);
    z-index: 1;
  }

  .q-slider__track-container {
    background: var(--track-bg);
    border-radius: 4px;
    height: 4px;
    margin-top: -2px;
    overflow: hidden;
  }

  .q-slider__track-markers {
    color: rgba($primary, 0.35);
    mix-blend-mode: difference;

    &::after {
      height: 4px;
      width: 3px;
    }
  }
}
</style>
