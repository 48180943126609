<template>
  <QBtn
    class="back text-bold"
    flat
    :icon="icon('chevronLeft')"
    rounded
    :to="to"
  >
    {{ person.firstName }} {{ person.lastName }}
  </QBtn>
</template>

<script>
import Vue from 'vue';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'PersonBack',
  mixins: [icons],
  props: {
    person: {
      type: Object,
      required: true,
    },
    to: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.back {
  .q-icon {
    margin-left: -#{map-get($space-sm, 'x')};
  }
}
</style>
