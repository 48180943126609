<template>
  <QForm class="form q-gutter-md">
    <div class="row no-wrap items-center" style="min-height: 40px">
      <Avatar
        v-if="form.person.id && person"
        class="q-mr-md"
        :person="person"
        size="lg"
      />
      <div class="ellipsis text-h6 q-pr-sm">
        <template v-if="!creating">
          <template v-if="slot">
            {{ slot.person.firstName }} on {{ humanStringDate(slot.date) }}
          </template>
        </template>
        <template v-else-if="form.person.id">
          {{ person && person.firstName }} on {{ humanStringDate(form.date) }}
        </template>
        <template v-else>
          Reserve someone on {{ humanStringDate(form.date) }}
        </template>
      </div>

      <QSpace />

      <slot name="controls" />
    </div>

    <template v-if="!creating && slot">
      <DurationField v-model="form.duration" :max="durationMax" />
      <Task
        background
        clickable
        full
        :task="slot.task"
        @click="openTask(slot.task.id)"
      />
      <TimingDetails
        :company="slot.task.project.company"
        :timing="slot.timing"
      />
    </template>

    <PeopleSelect
      v-if="creating && !form.person.id"
      v-model="form.person"
      autofocus
      :free="form.date"
      label="Select person"
    />
    <template v-if="creating && form.person.id">
      <DurationField v-model="form.duration" :max="durationMax" />

      <TaskForm v-if="newTask" id="new" :name="newTaskName">
        <template #controls>
          <QBtn
            :icon="icon('close')"
            flat
            round
            dense
            @click="newTask = false"
          />
        </template>
      </TaskForm>
      <template v-else>
        <TaskField
          v-model="form.task.id"
          :autofocus="$q.screen.gt.sm"
          @new-task="createTask"
        />
        <Task
          v-if="task"
          background
          caption="Re-use last task"
          clickable
          :task="task"
          @click="form.task.id = task.id"
        />
      </template>
    </template>

    <div v-if="!creating" class="form__bottom flex q-gutter-sm">
      <QBtn
        label="Delete booking"
        color="negative"
        :loading="deleting"
        @click="deleteSlot"
      />
    </div>
  </QForm>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { format, isSameDay, parseISO } from 'date-fns';
import { mapGetters, mapMutations } from 'vuex';

import DurationField from '@/components/forms/DurationField.vue';
import TaskField from '@/components/forms/TaskField.vue';
import Avatar from '@/components/people/Avatar.vue';
import PeopleSelect from '@/components/people/PeopleSelect.vue';
import TaskForm from '@/components/tasks/Form.vue';
import Task from '@/components/tasks/Task.vue';
import TimingDetails from '@/components/people/TimingDetails.vue';

import date from '@/mixins/date';
import icons from '@/mixins/icons';

import { PersonFree } from '@graphql/queries/people.gql';
import { Slot } from '@graphql/queries/slots.gql';
import { TaskBase } from '@graphql/queries/tasks.gql';
import {
  CreateSlot,
  DeleteSlot,
  UpdateSlot,
} from '@graphql/mutations/slots.gql';

import { EventBus } from '@/event-bus';

function formFromSlot(slot) {
  return Object.assign({}, slot, {
    person: {
      id: slot.person.id,
    },
    task: {
      id: slot.task.id,
    },
  });
}

function formFromQuery(query) {
  return {
    id: null,
    date: query?.date,
    duration: 1,
    person: {
      id: query?.personId,
    },
    task: {
      id: undefined,
    },
  };
}

export default Vue.extend({
  name: 'SlotForm',
  components: {
    Avatar,
    DurationField,
    PeopleSelect,
    Task,
    TaskField,
    TaskForm,
    TimingDetails,
  },
  apollo: {
    person: () => ({
      query: PersonFree,
      fetchPolicy: 'cache-and-network',
      variables() {
        return this.variables;
      },
      skip() {
        return !this.form.person.id;
      },
    }),
    slot: {
      query: Slot,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return this.creating;
      },
    },
    task: () => ({
      query: TaskBase,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          id: this.lastTask,
        };
      },
      skip() {
        return !this.lastTask;
      },
      error(error) {
        const errors = error.graphQLErrors.map(
          (error) => error.extensions.exception.name,
        );

        if (errors.includes('EntityNotFound')) {
          this.setLastTask('');
        }
      },
    }),
  },
  mixins: [date, icons],
  inject: ['closeForm', 'openSlot', 'openTask'],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data(vm) {
    return {
      deleting: false,
      form: formFromQuery(vm.$route.query),
      newTask: false,
      newTaskName: undefined,
      submitting: false,
    };
  },
  computed: {
    ...mapGetters(['lastTask']),
    creating() {
      return this.id === 'new';
    },
    durationMax() {
      return this.creating
        ? this.person?.agenda?.free
        : this.person?.agenda?.free + this.slot?.duration;
    },
    variables() {
      return {
        id: this.form.person.id,
        date: this.form.date,
      };
    },
  },
  watch: {
    activeDate(to) {
      if (this.creating) {
        this.form.date = format(to, 'yyyy-MM-dd');
      } else {
        if (!isSameDay(this.activeDate, parseISO(this.form.date))) {
          this.closeForm();
        }
      }
    },
    creating(to) {
      if (!to && this.slot) {
        this.form = formFromSlot(this.slot);
        this.alignDate(this.form.date);
      }
    },
    form: {
      handler(form) {
        if (this.creating) {
          if (
            form.date &&
            form.duration > 0 &&
            form.duration <= this.durationMax &&
            form.person.id &&
            form.task.id
          ) {
            this.createSlot();
          } else {
            const { date, personId } = this.$route.query;
            if (date != form.date || personId != form.person.id) {
              this.openSlot('new', {
                date: form.date,
                personId: form.person.id,
              });
            }
          }
        } else if (this.form.id && this.slot) {
          if (
            form.date != this.slot.date ||
            form.duration != this.slot.duration
          ) {
            this.updateSlot();
          }
        }
      },
      deep: true,
    },
    '$route.query'({ date, personId, slotId }) {
      if (slotId === 'new') {
        this.form = formFromQuery({ date, personId, slotId });
        this.alignDate(date);
      }
    },
    person(person) {
      if (person) {
        this.form.duration = Math.min(this.form.duration, this.durationMax);
      }
    },
    slot(slot) {
      if (this.creating) {
        return;
      }

      this.form = formFromSlot(slot);
      this.alignDate(slot.date);
    },
  },
  created() {
    this.alignDate(this.form.date);
    EventBus.$on('task-created', this.setTask);
  },
  beforeDestroy() {
    EventBus.$off('task-created', this.setTask);
  },
  methods: {
    ...mapMutations(['setActiveDate', 'setLastTask']),
    alignDate(isoDate) {
      if (!isoDate) {
        return;
      }

      const date = parseISO(isoDate);
      if (!isSameDay(this.activeDate, date)) {
        this.setActiveDate(date);
      }
    },
    createTask(value) {
      this.newTask = true;
      this.newTaskName = value;
    },
    deleteSlot() {
      this.deleting = true;

      this.$q
        .dialog({
          title: 'Are you sure?',
          message: `You are removing ${this.slot.duration} hours of ${this.slot.person.firstName} working on ${this.slot.task.name}.`,
          focus: 'none',
          ok: {
            color: 'negative',
          },
          cancel: {
            flat: true,
            textColor: 'grey-9',
          },
        })
        .onOk(async () => {
          try {
            const result = await this.$apollo.mutate({
              mutation: DeleteSlot,
              variables: {
                id: this.id,
              },
            });

            if (result.data.deleteSlot) {
              EventBus.$emit('slot-deleted', result.data.deleteSlot);
            }
          } catch (e) {
            Sentry.captureException(e);
            this.$q.notify({
              type: 'negative',
              message: 'Cannot delete slot',
              caption: 'Something went wrong, please try again',
              timeout: 10000,
            });
          } finally {
            this.deleting = false;
          }
        })
        .onCancel(() => {
          this.deleting = false;
        });
    },
    setTask(task) {
      this.newTask = false;
      this.form.task.id = task.id;
    },
    async createSlot() {
      if (this.submitting) {
        return;
      }

      this.submitting = true;

      try {
        const result = await this.$apollo.mutate({
          mutation: CreateSlot,
          variables: {
            fields: {
              date: this.form.date,
              duration: this.form.duration,
              personId: this.form.person.id,
              taskId: this.form.task.id,
            },
          },
        });

        if (result.data.createSlot) {
          this.setLastTask(result.data.createSlot.task.id);

          EventBus.$emit('slot-created', result.data.createSlot);
          this.openSlot(result.data.createSlot.id);
        }
      } catch (e) {
        Sentry.captureException(e);
        this.$q.notify({
          type: 'negative',
          message: 'Cannot create slot',
          caption: 'Something went wrong, please try again',
          timeout: 10000,
        });
      } finally {
        this.submitting = false;
      }
    },
    async updateSlot() {
      this.submitting = true;

      try {
        await this.$apollo.mutate({
          mutation: UpdateSlot,
          variables: {
            id: this.form.id,
            fields: {
              date: this.form.date,
              duration: this.form.duration,
            },
            date: this.form.date,
          },
        });
      } catch (e) {
        Sentry.captureException(e);
        this.$q.notify({
          type: 'negative',
          message: 'Cannot update slot',
          caption: 'Something went wrong, please try again',
          timeout: 10000,
        });
      } finally {
        this.submitting = false;
      }
    },
  },
});
</script>

<style lang="scss">
.angle {
  margin-bottom: -2em;
  margin-top: -0.5em;
}
</style>
