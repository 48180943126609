import { isWeekend, addBusinessDays } from 'date-fns';
import { Task, Person } from '@graphql/types';

export const projectKeys = [
  { name: 'id', weight: 0.5 },
  {
    name: 'company.name',
    weight: 0.35,
  },
  {
    name: 'name',
    weight: 0.15,
  },
];

export const taskKeys = [
  { name: 'project.id', weight: 0.4 },
  {
    name: 'project.company.name',
    weight: 0.25,
  },
  {
    name: 'name',
    weight: 0.25,
  },
  {
    name: 'project.name',
    weight: 0.1,
  },
];

export const taskOrders = {
  company_ASC: {
    compare(a: Task, b: Task) {
      const a1 = a.project.company.name;
      const a2 = a.project.name;
      const a3 = a.name;
      const b1 = b.project.company.name;
      const b2 = b.project.name;
      const b3 = b.name;

      return (
        a1.localeCompare(b1) * 100 +
        a2.localeCompare(b2) * 10 +
        a3.localeCompare(b3)
      );
    },
    label: 'A-Z',
  },
  account_ASC: {
    compare(a: Task, b: Task) {
      if (a.project.account && b.project.account) {
        if (a.project.account.lastName > b.project.account.lastName) {
          return 1;
        } else if (a.project.account.lastName < b.project.account.lastName) {
          return -1;
        }
      } else if (!a.project.account && b.project.account) {
        return 1;
      } else if (a.project.account && !b.project.account) {
        return -1;
      }

      return taskOrders['company_ASC'].compare(a, b);
    },
    label: 'Account',
  },
  delivery_ASC: {
    compare(a: Task, b: Task) {
      if (a.delivery && b.delivery) {
        if (a.delivery > b.delivery) {
          return 1;
        } else if (a.delivery < b.delivery) {
          return -1;
        }
      } else if (!a.delivery && b.delivery) {
        return 1;
      } else if (a.delivery && !b.delivery) {
        return -1;
      }

      return taskOrders['company_ASC'].compare(a, b);
    },
    label: 'Delivery',
  },
  updated_DESC: {
    compare(a: Task, b: Task) {
      if (a.updated < b.updated) {
        return 1;
      } else if (a.updated > b.updated) {
        return -1;
      }

      return taskOrders['company_ASC'].compare(a, b);
    },
    label: 'Updated',
  },
};

export function fullName(person: Person) {
  return `${person.firstName} ${person.lastName}`;
}

export function nextBusinessDay(date: Date) {
  return isWeekend(date) ? addBusinessDays(date, 1) : date;
}

export function personToOption(person: Person) {
  return {
    label: fullName(person),
    person,
    value: person.id,
  };
}

export function projectIdToParam(id: string) {
  return id.replace('/', '-');
}

export function projectParamToId(param: string) {
  return param.replace('-', '/');
}
