<template>
  <QIcon v-if="value !== 'medium'" :class="classes" :name="icon(name)" />
</template>

<script>
import Vue from 'vue';

const priorities = {
  high: { class: 'text-negative', name: 'exclamationThick' },
  low: { class: 'text-green', name: 'arrowDown' },
  medium: { class: 'text-primary', name: 'swapHorizontal' },
};

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'Priority',
  mixins: [icons],
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    classes() {
      return priorities[this.value].class;
    },
    name() {
      return priorities[this.value].name;
    },
  },
});
</script>
