import { render, staticRenderFns } from "./Calendar.vue?vue&type=template&id=1ad7262f&"
import script from "./Calendar.vue?vue&type=script&lang=js&"
export * from "./Calendar.vue?vue&type=script&lang=js&"
import style0 from "./Calendar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';import TouchSwipe from 'quasar/src/directives/TouchSwipe.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QScrollArea,QSkeleton,QAvatar});qInstall(component, 'directives', {TouchSwipe});
