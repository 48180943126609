import { format, parseISO } from 'date-fns';
import { mapGetters } from 'vuex';

type MixinTarget = Vue & {
  activeDate: Date;
  isoDate(date: Date): string;
};

export default {
  computed: {
    ...mapGetters(['activeDate']),
    formattedActiveDate(this: MixinTarget) {
      return this.isoDate(this.activeDate);
    },
  },
  methods: {
    isoDate(date: Date) {
      return format(date, 'yyyy-MM-dd');
    },
    isoStringDate(date: string) {
      return this.isoDate(this.parse(date));
    },
    humanDate(date: Date) {
      return format(date, 'dd/MM');
    },
    humanStringDate(date: string) {
      return this.humanDate(this.parse(date));
    },
    parse(date: string) {
      return parseISO(date);
    },
  },
};
