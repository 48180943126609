import colors from 'quasar/src/utils/colors.js';;

import { Company, Project, Slot, Task } from '@graphql/types';

const { hexToRgb } = colors;

type OneOf<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

type MixinTarget = Vue &
  OneOf<{ task: Task; slot: Slot; project: Project; company: Company }> & {
    background: boolean;
  };

export default {
  props: {
    background: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backgroundClasses(this: MixinTarget) {
      return {
        rgb: true,
        'rgb--background': this.background,
        'rounded-borders': this.background,
      };
    },
    companyRGB(this: MixinTarget) {
      const src =
        this.slot?.task?.project?.company ||
        this.task?.project?.company ||
        this.project?.company ||
        this.company;

      if (!src) {
        throw new Error('Trying to calculate companyRGB without a company');
      }

      const c = hexToRgb(src.color);
      return [c.r, c.g, c.b];
    },
  },
};
