<template>
  <section>
    <QInput
      v-model="query"
      :class="searchClasses"
      :clear-icon="icon('close')"
      clearable
      :label="label"
      rounded
      stack-label
      standout
      type="search"
      @keydown.enter="(e) => e.target.blur()"
    />
    <slot />
  </section>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'Search',
  mixins: [icons],
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      storedQuery: 'query',
    }),
    query: {
      get() {
        return this.storedQuery;
      },
      set(value) {
        this.setQuery(value);
      },
    },
    searchClasses() {
      return {
        'q-pt-lg': this.$q.screen.gt.sm,
        'q-pt-md': this.$q.screen.lt.md,
        'q-px-lg': this.$q.screen.gt.sm,
        'q-px-md': this.$q.screen.lt.md,
      };
    },
  },
  methods: {
    ...mapMutations(['setQuery']),
  },
});
</script>
