<template>
  <QDrawer
    v-if="$q.screen.gt.sm"
    v-model="hasForm"
    elevated
    overlay
    persistent
    side="right"
    :width="450"
  >
    <QScrollArea class="fit">
      <component
        :is="form.component"
        v-if="form"
        class="q-pa-lg"
        v-bind="form.props"
      >
        <template #controls>
          <QBtn :icon="icon('close')" flat round dense @click="closeForm" />
        </template>
      </component>
    </QScrollArea>
  </QDrawer>

  <QDialog
    v-else
    v-model="hasForm"
    class="q-pa-none"
    full-width
    no-route-dismiss
    position="bottom"
  >
    <QCard class="curved">
      <component
        :is="form.component"
        v-if="form"
        class="q-pa-lg"
        v-bind="form.props"
      >
        <template #controls>
          <QBtn :icon="icon('close')" flat round dense @click="closeForm" />
        </template>
      </component>
    </QCard>
  </QDialog>
</template>

<script>
import Vue from 'vue';

import SlotForm from '@/components/calendar/Form.vue';
import TaskForm from '@/components/tasks/Form.vue';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'AutoForm',
  mixins: [icons],
  inject: ['closeForm'],
  computed: {
    form() {
      const { slotId, taskId } = this.$route.query;

      if (slotId) {
        return {
          component: SlotForm,
          props: { id: slotId },
        };
      }

      if (taskId) {
        return {
          component: TaskForm,
          props: { id: taskId },
        };
      }

      return false;
    },
    hasForm: {
      get() {
        return !!this.form;
      },
      set(val) {
        if (!val) {
          this.closeForm();
        }
      },
    },
  },
});
</script>
