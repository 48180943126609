<template>
  <div
    class="tasklist q-px-md q-pb-lg q-mb-md"
    :style="{ 'margin-top': $q.screen.gt.sm ? '8px' : '0' }"
  >
    <div :class="`tasks--${$q.screen.gt.sm ? 'desktop' : 'mobile'}`">
      <div :class="`task__header--${$q.screen.gt.sm ? 'desktop' : 'mobile'}`">
        <div>
          <QSkeleton animation="blink" type="text" />
        </div>
      </div>
      <QSkeleton
        v-for="(_, i) in Array($q.screen.gt.sm ? 7 : 4)"
        :key="i"
        animation="blink"
        :class="`task task--${$q.screen.gt.sm ? 'desktop' : 'mobile'}`"
        type="rect"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'TaskLoading',
});
</script>
