import {
  addBusinessDays,
  addMonths,
  addWeeks,
  subBusinessDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import VuexPersistence from 'vuex-persist';

import { init as initStorage } from '@/storage';
import { nextBusinessDay, taskOrders } from '@/utils';

Vue.use(Vuex);

type State = {
  activeDate: Date;
  connected: boolean;
  darkMode: boolean;
  direction: 'left' | 'right';
  homeScreen: 'calendar' | 'calendar_person' | 'tasks' | 'tasks_person';
  lastTask?: string;
  offline: boolean;
  pendingForm?: (next: () => void) => void;
  query?: string;
  swReg?: ServiceWorkerRegistration;
  taskClosed: boolean;
  taskOrder: keyof typeof taskOrders;
  updating: boolean;
  user?: {
    token: string;
    userId: string;
  };
  weeklyView: boolean;
};

type RestorableStore = Store<State> & {
  restored: Promise<never>;
};

let disconnecting: NodeJS.Timeout | undefined;
let store: RestorableStore;

export async function init() {
  if (store) {
    return store;
  }

  const vuexLocal = new VuexPersistence<State>({
    asyncStorage: true,
    reducer(state) {
      const {
        darkMode,
        homeScreen,
        lastTask,
        taskOrder,
        user,
        weeklyView,
      } = state;
      return { darkMode, homeScreen, lastTask, taskOrder, user, weeklyView };
    },
    storage: await initStorage(),
  });

  store = new Vuex.Store<State>({
    state: {
      activeDate: nextBusinessDay(new Date()),
      connected: true,
      darkMode: false,
      direction: 'left',
      homeScreen: 'calendar',
      lastTask: undefined,
      offline: false,
      pendingForm: undefined,
      query: undefined,
      swReg: undefined,
      taskClosed: false,
      taskOrder: 'company_ASC',
      updating: false,
      user: undefined,
      weeklyView: true,
    },
    getters: {
      activeDate(state) {
        return state.activeDate;
      },
      connected(state) {
        return state.connected;
      },
      darkMode(state) {
        return state.darkMode;
      },
      direction(state) {
        return state.direction;
      },
      hasPendingForm(state) {
        return !!state.pendingForm;
      },
      homeScreen(state) {
        return state.homeScreen;
      },
      lastTask(state) {
        return state.lastTask;
      },
      offline(state) {
        return state.offline;
      },
      pendingForm(state) {
        return state.pendingForm;
      },
      query(state) {
        return state.query;
      },
      swReg(state) {
        return state.swReg;
      },
      taskClosed(state) {
        return state.taskClosed;
      },
      taskOrder(state) {
        return state.taskOrder;
      },
      token(state) {
        return state.user?.token;
      },
      username(state) {
        return state.user?.userId;
      },
      weeklyView(state) {
        return state.weeklyView;
      },
      working(state) {
        return !state.offline && state.connected;
      },
    },
    mutations: {
      setActiveDate(state, date) {
        const valid = nextBusinessDay(date);

        state.direction = state.activeDate < valid ? 'left' : 'right';
        state.activeDate = valid;
      },
      setConnected(state, value) {
        if (disconnecting) {
          clearTimeout(disconnecting);
          disconnecting = undefined;
        }

        if (state.connected === value) {
          return;
        }

        if (!value) {
          disconnecting = setTimeout(() => {
            state.connected = value;
          }, 2000);
        } else {
          state.connected = value;
        }
      },
      setDarkMode(state, value) {
        state.darkMode = value;
      },
      setDateNext(state, interval) {
        switch (interval) {
          case 'day':
            store.commit('setActiveDate', addBusinessDays(state.activeDate, 1));
            break;
          case 'week':
            store.commit('setActiveDate', addWeeks(state.activeDate, 1));
            break;
          case 'month':
            store.commit('setActiveDate', addMonths(state.activeDate, 1));
            break;
          default:
            throw new Error(`Unknown interval ${interval} in setDateNext`);
        }
      },
      setDatePrev(state, interval) {
        switch (interval) {
          case 'day':
            store.commit('setActiveDate', subBusinessDays(state.activeDate, 1));
            break;
          case 'week':
            store.commit('setActiveDate', subWeeks(state.activeDate, 1));
            break;
          case 'month':
            store.commit('setActiveDate', subMonths(state.activeDate, 1));
            break;
          default:
            throw new Error(`Unknown interval ${interval} in setDatePrev`);
        }
      },
      setHomeScreen(state, value) {
        state.homeScreen = value;
      },
      setLastTask(state, value) {
        state.lastTask = value;
      },
      setOffline(state, value) {
        state.offline = !!value;
      },
      setPendingForm(state, value) {
        state.pendingForm = value;
      },
      setQuery(state, value) {
        state.query = value;
      },
      setSWReg(state, value) {
        state.swReg = value;
      },
      setTaskClosed(state, value) {
        state.taskClosed = value;
      },
      setTaskOrder(state, value) {
        state.taskOrder = value;
      },
      setUpdating(state) {
        state.updating = true;
      },
      setUser(state, value) {
        state.user = value;
      },
      setWeeklyView(state, value) {
        state.weeklyView = value;
      },
    },
    actions: {},
    plugins: [vuexLocal.plugin],
  }) as RestorableStore;

  window.addEventListener('offline', () => {
    store.commit('setOffline', true);
  });

  window.addEventListener('online', () => {
    store.commit('setOffline', false);
  });

  await store.restored;

  return store;
}
