import Vue from 'vue';

import App from '@/App.vue';
import { init as initGtag } from '@/gtag';
import { init as initQuasar } from '@/quasar';
import { init as initRouter } from '@/router';
import { init as initSentry } from '@/sentry';
import { init as initStore } from '@/store';
import { init as initUpdate } from '@/update';
import { init as initWorker } from '@/worker';
import { createProvider } from '@/vue-apollo';

import '@/styles/app.scss';

import VueClipboard from 'vue-clipboard2';
import PortalVue from 'portal-vue';

Vue.use(VueClipboard);
Vue.use(PortalVue, {
  portalName: 'Portal',
  portalTargetName: 'PortalTarget',
});

Vue.config.productionTip = false;

async function bootstrap() {
  await initGtag();
  await initSentry();
  await initUpdate();

  initQuasar(Vue);
  initWorker();

  new Vue({
    router: await initRouter(),
    store: await initStore(),
    apolloProvider: await createProvider(),
    render: (h) => h(App),
  }).$mount('#app');
}

bootstrap();
