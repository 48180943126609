<template>
  <QSelect
    ref="select"
    :label="`Project${validate ? ' *' : ''}`"
    :rules="validate ? [(val) => !!val || 'Field is required'] : []"
    hide-bottom-space
    :hide-selected="hide"
    rounded
    stack-label
    standout
    use-input
    :options="options"
    :value="project"
    @filter="projectsFilter"
    @popup-show="hide = true"
    @popup-hide="hide = false"
    @input="onInput"
  >
    <template #selected-item="{ opt, itemProps, itemEvents }">
      <Project
        class="q-px-none q-pt-sm q-pb-xs"
        :company="!projects"
        :project="opt.project"
        v-bind="itemProps"
        v-on="itemEvents"
      />
    </template>

    <template #option="{ opt, itemProps, itemEvents }">
      <Project
        :key="opt.project.id"
        :company="!projects"
        :project="opt.project"
        v-bind="itemProps"
        v-on="itemEvents"
      />
    </template>

    <template #no-option>
      <QItem>
        <QItemSection class="text-grey">No results</QItemSection>
      </QItem>
    </template>
  </QSelect>
</template>

<script>
import Vue from 'vue';

import Project from '@/components/projects/Project.vue';

import { ProjectList } from '@graphql/queries/projects.gql';

import { Searcher } from '@/search';
import { projectKeys } from '@/utils';

export function toOption(project) {
  return {
    project,
    value: project.id,
  };
}

export default Vue.extend({
  name: 'ProjectField',
  apollo: {
    allProjects: () => ({
      query: ProjectList,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !!this.projects;
      },
      update(data) {
        return data.projects;
      },
    }),
  },
  components: {
    Project,
  },
  props: {
    projects: {
      type: Array,
      default: undefined,
    },
    validate: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      hide: false,
      options: [],
    };
  },
  computed: {
    project() {
      return this.value ? toOption(this.value) : undefined;
    },
    searcher() {
      return new Searcher(this.visibleProjects, projectKeys);
    },
    visibleProjects() {
      return [...(this.projects || this.allProjects || [])];
    },
  },
  methods: {
    focus() {
      return this.$refs.select.focus();
    },
    onInput(val) {
      this.$emit('input', val.project);
      this.$refs.select.blur();
    },
    projectsFilter(val, update) {
      update(() => {
        if (!val.length) {
          this.options = Object.freeze(this.visibleProjects.map(toOption));
        } else {
          this.options = Object.freeze(
            this.searcher.search(val).map((r) => toOption(r.item)),
          );
        }
      });
    },
  },
});
</script>
