import { parseISO, isAfter } from 'date-fns';

import { Task } from '@graphql/types';

import Avatar from '@/components/people/Avatar.vue';
import Priority from '@/components/tasks/Priority.vue';

import color from '@/mixins/color';
import date from '@/mixins/date';

type MixinTarget = Vue &
  typeof color.computed &
  typeof date.computed & {
    task: Task;
  };

export default {
  mixins: [color, date],
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  inject: ['openTask'],
  components: {
    Avatar,
    Priority,
  },
  computed: {
    classes(this: MixinTarget) {
      return {
        task: true,
        'task--desktop': this.$options.name === 'TaskDesktop',
        'task--mobile': this.$options.name === 'TaskMobile',
        'task--selected': this.task.id === this.$route.query.taskId,
        [`task--${this.task.status}`]: true,
      };
    },
    overdue(this: MixinTarget) {
      if (!this.task.delivery) {
        return false;
      }

      return isAfter(new Date(), parseISO(this.task.delivery.toString()));
    },
    status(this: MixinTarget) {
      return {
        planning: 'PLAN',
        working: 'WORK',
        feedback: 'FDBK',
        closed: 'CLOSED',
      }[this.task.status];
    },
    style(this: MixinTarget) {
      return { '--company-rgb': this.companyRGB };
    },
  },
};
