<template>
  <QScrollArea class="column col-grow">
    <Portal to="left">
      <Search label="Search contacts...">
        <Drawer v-if="groups" :groups="contacts" />
      </Search>
    </Portal>

    <Search v-if="$q.screen.lt.md" label="Search contacts..." />
    <ErrorState v-if="error" @retry="retry" />
    <div
      v-else-if="$apolloData.loading && !groups"
      class="q-px-md q-pt-xs q-pb-sm"
    >
      <GroupLoading :items="6" />
      <GroupLoading :items="3" />
    </div>
    <div
      v-else-if="contacts && contacts.length > 0"
      class="q-px-md q-pt-xs q-pb-sm"
    >
      <Group
        v-for="group in contacts"
        :id="group.id"
        :key="group.id"
        :item="component"
        :items="group.people"
        :name="group.name"
        prop="person"
      />
    </div>
    <div v-else class="q-pa-xl text-center text-h6 text-grey">No results</div>
  </QScrollArea>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Contact from '@/components/contacts/Contact.vue';
import Drawer from '@/components/contacts/Drawer.vue';
import Group from '@/components/contacts/Group.vue';
import GroupLoading from '@/components/contacts/GroupLoading.vue';
import ErrorState from '@/components/layout/ErrorState.vue';
import Search from '@/components/layout/Search.vue';

import { Groups } from '@graphql/queries/groups.gql';

export default Vue.extend({
  name: 'Contacts',
  apollo: {
    groups: () => ({
      query: Groups,
      fetchPolicy: 'cache-and-network',
      result(res) {
        this.error = res.error;
      },
    }),
  },
  components: {
    Drawer,
    ErrorState,
    Group,
    GroupLoading,
    Search,
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    ...mapGetters(['query']),
    component() {
      return Contact;
    },
    contacts() {
      if (!this.query) {
        return Object.freeze(this.groups);
      }

      const query = this.query.toLowerCase();

      return Object.freeze(
        this.groups
          .map((group) => {
            return {
              ...group,
              people: group.people
                .filter(
                  (person) =>
                    person.firstName.toLowerCase().includes(query) ||
                    person.lastName.toLowerCase().includes(query),
                )
                .sort((a, b) => a.lastName.localeCompare(b.lastName)),
            };
          })
          .filter((group) => group.people.length > 0),
      );
    },
  },
  methods: {
    retry() {
      this.$apollo.queries.groups.refetch();
    },
  },
});
</script>
