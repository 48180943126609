import { VueConstructor } from 'vue/types/umd';

import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import TouchSwipe from 'quasar/src/directives/TouchSwipe.js';;

import iconSet from 'quasar/icon-set/svg-mdi-v5';

export function init(Vue: VueConstructor<Vue>) {
  Vue.use(Quasar, {
    config: {
      notify: {
        color: 'primary',
        textColor: 'white',
      },
    },
    components: {},
    directives: {
      TouchSwipe,
    },
    plugins: {
      Dialog,
      Notify,
    },
    iconSet,
  });
}
