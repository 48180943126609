<template>
  <div :class="classes" @click="openSlot('new', query)">
    <div :class="innerClasses">
      <QBtn
        class="q-ma-md"
        color="grey"
        round
        flat
        size="sm"
        :icon="icon('plus')"
      />
      <div>
        <div
          class="text-bold"
          :class="$q.screen.gt.sm ? 'ellipsis-2-lines' : 'ellipsis-3-lines'"
        >
          {{ duration }}h <span class="optional">free</span>
        </div>
        <div class="optional">Click to book</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'Free',
  mixins: [icons],
  inject: ['openSlot'],
  props: {
    duration: {
      type: Number,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
  },
  computed: {
    classes() {
      return {
        slot: true,
        'slot--free': true,
        'slot--selected': this.selected,
        [`duration-${this.duration}`]: true,
      };
    },
    innerClasses() {
      const classes = [
        'slot__inner',
        'rounded-borders',
        'cursor-pointer',
        'no-wrap',
        'items-center',
      ];

      classes.push(this.$route.params.personId ? 'column' : 'row');

      return classes;
    },
    selected() {
      if (this.$route.query.slotId !== 'new') {
        return false;
      }

      for (const [key, value] of Object.entries(this.query)) {
        if (this.$route.query[key] !== value) {
          return false;
        }
      }

      return true;
    },
  },
});
</script>

<style lang="scss">
@media (max-width: $breakpoint-sm-max) {
  .slot--free.duration-1 .optional {
    display: none;
  }
}
</style>
