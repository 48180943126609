<template>
  <QHeader>
    <StatusBar v-if="$q.screen.gt.sm" />

    <QToolbar class="justify-between">
      <QBtn
        dense
        flat
        round
        :icon="icon('logo')"
        size="lg"
        :to="{ name: 'home' }"
      />

      <Menu v-if="$q.screen.gt.sm" />

      <QBtnDropdown dense flat no-caps rounded split :to="personCalendar">
        <template #label>
          <QSkeleton
            v-if="$apolloData.loading"
            animation="blink"
            size="lg"
            type="QAvatar"
          />
          <Avatar v-else :person="currentUser" size="lg" />
        </template>

        <QList>
          <QItem :to="{ name: 'settings' }">
            <QItemSection side>
              <QIcon dense :name="icon('cogOutline')" />
            </QItemSection>
            <QItemSection>Settings</QItemSection>
          </QItem>
          <QItem :to="{ name: 'logout' }">
            <QItemSection side>
              <QIcon color="negative" dense :name="icon('exitToApp')" />
            </QItemSection>
            <QItemSection>Logout</QItemSection>
          </QItem>
        </QList>
      </QBtnDropdown>
    </QToolbar>
  </QHeader>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Menu from '@/components/layout/Menu.vue';
import StatusBar from '@/components/layout/StatusBar.vue';
import Avatar from '@/components/people/Avatar.vue';

import icons from '@/mixins/icons';

import { CurrentUser } from '@graphql/queries/people.gql';

export default Vue.extend({
  name: 'Header',
  components: {
    Avatar,
    Menu,
    StatusBar,
  },
  apollo: {
    currentUser: {
      query: CurrentUser,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.username;
      },
    },
  },
  mixins: [icons],
  computed: {
    ...mapGetters(['username', 'working']),
    currentRoute() {
      return this.$route.path.split('/')[1];
    },
    personCalendar() {
      if (!this.username) {
        return undefined;
      }

      return {
        name: 'calendar_person',
        params: { personId: this.username },
      };
    },
  },
  watch: {
    working(working) {
      if (working && !this.currentUser) {
        this.$apollo.queries.currentUser.refetch();
      }
    },
  },
});
</script>

<style lang="scss">
.q-header {
  padding-top: env(safe-area-inset-top);

  &::before,
  &::after {
    height: $default-border-radius;
    position: absolute;
    top: 100%;
    width: $default-border-radius;
    z-index: 1;
  }

  &::before {
    background-image: radial-gradient(
      circle at 100% 100%,
      transparent $default-border-radius,
      var(--q-color-primary) calc(#{$default-border-radius} + 0.01em)
    );
    content: '';
    left: 0;
  }

  .q-btn-dropdown--split .q-btn-dropdown__arrow-container {
    border-left: none;
  }

  @media (max-width: $breakpoint-sm-max) {
    &::after {
      background-image: radial-gradient(
        circle at 0% 100%,
        transparent $default-border-radius,
        var(--q-color-primary) calc(#{$default-border-radius} + 0.01em)
      );
      content: '';
      right: 0;
    }
  }

  .body--dark & {
    background-color: $darkish;

    --q-color-primary: #{$darkish};
  }
}
</style>
