<template>
  <QItem
    class="task--card"
    :class="customClasses"
    :style="{ '--company-rgb': companyRGB }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <QItemSection>
      <QItemLabel v-if="caption" caption>
        {{ caption }}
      </QItemLabel>

      <QItemLabel v-if="project" lines="1">
        <strong class="company">
          {{ task.project.company.name }}
        </strong>
        <span class="q-ml-xs">
          {{ task.project.name }}
        </span>
      </QItemLabel>
      <QItemLabel lines="2">
        {{ task.name }}
      </QItemLabel>

      <template v-if="full">
        <QItemLabel v-if="task.notes" caption class="text-italic">
          {{ task.notes }}
        </QItemLabel>

        <QSpace style="min-height: 16px" />

        <QItemLabel class="flex no-wrap items-baseline q-gutter-xs">
          <Priority :value="task.priority" />
          <small v-if="task.delivery" class="text-bold">
            {{ humanStringDate(task.delivery) }}
          </small>
          <QSpace />
          <template v-if="project">
            <div class="projectid text-h6 text-weight-light">
              {{ task.project.id }}
            </div>
            <Avatar
              v-if="task.project.projectLeader"
              :person="task.project.projectLeader"
              size="md"
            />
            <Avatar
              v-if="task.project.account"
              :person="task.project.account"
              size="md"
            />
          </template>
        </QItemLabel>
      </template>
    </QItemSection>
  </QItem>
</template>

<script>
import Vue from 'vue';

import color from '@/mixins/color';
import date from '@/mixins/date';
import task from '@/mixins/task';

export default Vue.extend({
  name: 'Task',
  mixins: [color, date, task],
  props: {
    caption: {
      type: String,
      default: undefined,
    },
    full: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    customClasses() {
      return {
        ...this.classes,
        ...this.backgroundClasses,
        task: false,
        'task--full': this.full,
      };
    },
  },
});
</script>

<style lang="scss">
.task {
  &.q-item {
    min-height: 0;
  }
}
</style>
