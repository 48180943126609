<template>
  <QScrollArea class="column col-grow">
    <Toolbar />

    <ErrorState v-if="error" @retry="refresh" />
    <TaskLoading v-else-if="loading && !updated" />
    <div v-else-if="taskList.length" class="tasklist q-px-md q-pb-xl q-mb-xl">
      <component :is="component" ref="list" :list="taskList" />
    </div>
    <div v-else class="q-pa-xl text-center text-h6 text-grey">No results</div>

    <Fab @click="openTask('new')" />
  </QScrollArea>
</template>

<script>
import Vue from 'vue';
import { NetworkStatus } from 'apollo-client';
import { mapGetters, mapMutations } from 'vuex';

import Fab from '@/components/layout/Fab.vue';
import ErrorState from '@/components/layout/ErrorState.vue';
import TaskForm from '@/components/tasks/Form.vue';
import ListDesktop from '@/components/tasks/ListDesktop.vue';
import ListMobile from '@/components/tasks/ListMobile.vue';
import TaskLoading from '@/components/tasks/Loading.vue';
import Toolbar from '@/components/tasks/Toolbar.vue';

import {
  Tasks,
  TaskCreated,
  TaskDeleted,
  TaskUpdated,
} from '@graphql/queries/tasks.gql';

import { EventBus } from '@/event-bus';
import icons from '@/mixins/icons';
import { Searcher } from '@/search';
import { taskKeys, taskOrders } from '@/utils';

export default Vue.extend({
  name: 'Tasks',
  apollo: {
    tasks: () => ({
      query: Tasks,
      fetchPolicy: 'cache-and-network',
      subscribeToMore: [
        {
          document: TaskCreated,
          updateQuery(data, { subscriptionData }) {
            const created = subscriptionData.data.taskCreated;
            return { tasks: data.tasks.concat([created]) };
          },
        },
        {
          document: TaskDeleted,
          updateQuery(data, { subscriptionData }) {
            const id = subscriptionData.data.taskDeleted;
            return { tasks: data.tasks.filter((task) => task.id !== id) };
          },
        },
        {
          document: TaskUpdated,
          updateQuery(data, { subscriptionData }) {
            const updated = subscriptionData.data.taskUpdated;
            return {
              tasks: data.tasks.map((task) =>
                task.id === updated.id ? updated : task,
              ),
            };
          },
        },
      ],
      result(res) {
        this.error = res.error;
      },
    }),
  },
  components: {
    ErrorState,
    Fab,
    TaskForm,
    TaskLoading,
    Toolbar,
  },
  mixins: [icons],
  inject: ['openTask'],
  props: {
    personId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    ...mapGetters(['taskClosed', 'taskOrder', 'query']),
    component() {
      return this.$q.screen.gt.sm ? ListDesktop : ListMobile;
    },
    loading() {
      return this.$apolloData.loading;
    },
    searcher() {
      return new Searcher(this.visibleTasks, taskKeys);
    },
    taskList() {
      if (!this.query) {
        return this.visibleTasks;
      }

      return Object.freeze(this.searcher.search(this.query).map((r) => r.item));
    },
    updated() {
      return !!this.tasks;
    },
    visibleTasks() {
      let values = [...(this.tasks || [])];

      if (!this.taskClosed) {
        values = values.filter((task) => task.status !== 'closed');
      }

      if (this.personId) {
        values = values.filter(
          (task) =>
            (this.personId === 'unassigned' && !task.project.account) ||
            task.project.account?.id === this.personId,
        );
      }

      return Object.freeze(values.sort(taskOrders[this.taskOrder].compare));
    },
  },
  watch: {
    '$route.query.taskId': {
      handler(id) {
        if (!id || id === 'new') {
          return;
        }

        this.$nextTick(() => {
          if (!this.$apollo.queries.tasks.loading) {
            this.scrollTo(id);
          }

          const subscription = this.$apollo.queries.tasks.observer.subscribe(
            (state) => {
              switch (state.networkStatus) {
                case NetworkStatus.ready:
                  subscription.unsubscribe();
                  this.scrollTo(id);

                  break;
                case NetworkStatus.error:
                  subscription.unsubscribe();
              }
            },
          );
        });
      },
      immediate: true,
    },
  },
  created() {
    EventBus.$on('task-created', this.created);
  },
  beforeDestroy() {
    EventBus.$off('task-created', this.created);
  },
  methods: {
    ...mapMutations(['setQuery']),
    created(task) {
      this.setQuery(null);

      if (
        this.$route.params.personId &&
        this.$route.params.personId !== task.project.account?.id
      ) {
        return this.$router.replace({
          name: 'tasks_person',
          params: {
            personId: task.project.account?.id || 'unassigned',
          },
          query: {
            taskId: task.id,
          },
        });
      }

      this.openTask(task.id);
    },
    refresh() {
      this.$apollo.queries.tasks.refetch();
    },
    scrollTo(id) {
      const el = this.$el.querySelector(`[data-task="${id}"]`);

      if (!el) {
        return;
      }

      this.$nextTick(() =>
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        }),
      );
    },
  },
});
</script>
