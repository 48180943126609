import Vue from 'vue';
import VueGtag, { screenview } from 'vue-gtag';
import { Route } from 'vue-router';

import { init as initRouter } from '@/router';
import { init as initStore } from '@/store';

function template(to: Route) {
  return {
    app_name: 'rolodex',
    page_title: to.name,
    page_path: to.path,
    page_location: to.fullPath,
    screen_name: to.name || 'unnamed',
  };
}

export async function init() {
  const router = await initRouter();
  const store = await initStore();

  Vue.use(
    VueGtag,
    {
      config: {
        id: 'G-EXBCGYMLYV',
        params: {
          send_page_view: false,
          user_id: store.getters.username,
        },
      },
      pageTrackerScreenviewEnabled: true,
      pageTrackerTemplate: template,
    },
    router,
  );

  // vue-gtag doesn't track the initial navigation
  router.onReady(() => {
    screenview(template(router.currentRoute));
  });
}
