import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import Vue from 'vue';

import { name, version } from '../package.json';
import { SENTRY_DSN } from '@graphql/config.ts';
import { init as initStore } from '@/store';

export async function init() {
  Sentry.init({
    dsn: SENTRY_DSN,
    ignoreErrors: [/NavigationDuplicated/],
    integrations: [
      new VueIntegration({ Vue, attachProps: true, logErrors: true }),
    ],
    release: `${name}@${version}`,
  });

  (await initStore()).watch(
    (state, getters) => getters.username,
    (username) => {
      Sentry.configureScope(function (scope) {
        scope.setUser({ id: username });
      });
    },
    {
      immediate: true,
    },
  );
}
