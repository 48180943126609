<template>
  <table class="tasks--desktop">
    <tbody>
      <template v-for="(task, i) of list">
        <HeaderDesktop
          v-if="
            !list[i - 1] ||
            task.project.company.id !== list[i - 1].project.company.id
          "
          :key="`company-${task.id}`"
          :task="task"
        />
        <TaskDesktop :key="`task-${task.id}`" :task="task" />
      </template>
    </tbody>
  </table>
</template>

<script>
import Vue from 'vue';

import HeaderDesktop from '@/components/tasks/HeaderDesktop.vue';
import TaskDesktop from '@/components/tasks/TaskDesktop.vue';

export default Vue.extend({
  name: 'ListDesktop',
  components: {
    HeaderDesktop,
    TaskDesktop,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.tasks--desktop {
  border-spacing: 0 4px;
  max-width: calc(#{$default-max-width} - #{map-get($space-md, 'x')} * 2);
  width: 100%;
}
</style>
