<template>
  <div
    class="contact rounded-borders"
    :class="{ [`contact--${person.company}`]: true, 'contact--open': open }"
  >
    <div class="q-gutter-md">
      <div class="flex items-center justify-between">
        <Avatar :person="person" size="xl" />
        <QBtn dense flat round :icon="icon(toggleIcon)" @click="open = !open" />
      </div>

      <div>
        <div class="company text-uppercase text-bold ellipsis">
          {{ person.firstName }} {{ person.lastName }}
        </div>
        <div class="ellipsis">{{ person.jobTitle }}</div>
      </div>

      <div class="flex items-center justify-between q-mr-sm">
        <div class="company text-h6">
          {{ person.telephone }}
        </div>
        <a :href="`mailto:${person.email}`">
          <QIcon :name="icon('email')" size="sm" />
        </a>
        <a :href="`tel:${person.mobile}`">
          <QIcon :name="icon('phone')" size="sm" />
        </a>
      </div>
      <div v-if="open" class="q-gutter-y-sm">
        <QSeparator class="q-my-md" />
        <div><strong>Company:</strong> {{ person.company }}</div>
        <div><strong>Email:</strong> {{ person.email }}</div>
        <div><strong>Phone:</strong> {{ person.mobile }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import Avatar from '@/components/people/Avatar.vue';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'Contact',
  components: {
    Avatar,
  },
  mixins: [icons],
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    toggleIcon() {
      return this.open ? 'minus' : 'plus';
    },
  },
});
</script>

<style lang="scss">
.contact {
  padding: $even-padding;

  @each $name, $color in $companies {
    &--#{$name} {
      background-color: rgba($color, 0.15);

      .company {
        color: $color;
      }
    }
  }
}
</style>
