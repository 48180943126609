<template>
  <section
    class="dater q-pa-md overflow-hidden"
    :class="{ singleweek: singleWeek }"
  >
    <div
      class="flex items-center q-pb-md q-pt-xs q-px-sm"
      :class="[singleWeek ? 'justify-between' : 'justify-around']"
      style="min-height: 56px"
    >
      <QBtn
        v-if="!singleWeek"
        dense
        flat
        round
        :icon="icon('chevronLeft')"
        @click="setDatePrev('month')"
      />
      <div class="text-h5 text-center text-bold">
        {{ monthName }}
      </div>

      <div v-if="singleWeek" class="q-gutter-x-sm">
        <QBtn
          dense
          flat
          round
          :icon="icon('chevronLeft')"
          @click="weekly ? setDatePrev('week') : setDatePrev('day')"
        />
        <QBtnToggle
          v-if="toggleWeekly"
          v-model="weekly"
          class="text-bold"
          no-caps
          rounded
          toggle-color="primary"
          :options="[
            { label: 'D', value: false },
            { label: 'W', value: true },
          ]"
        />
        <QBtn
          dense
          flat
          round
          :icon="icon('chevronRight')"
          @click="weekly ? setDateNext('week') : setDateNext('day')"
        />
      </div>

      <QBtn
        v-if="!singleWeek"
        dense
        flat
        round
        :icon="icon('chevronRight')"
        @click="setDateNext('month')"
      />
    </div>

    <transition :name="`slide-${direction}`" tag="div" mode="out-in">
      <div
        :key="singleWeek ? weekNumber : monthName"
        v-touch-swipe.horizontal="swipe"
      >
        <DaterWeek
          v-for="(week, i) in weeks"
          :key="i"
          :show-weekend="showWeekend"
          :week="week"
        />
      </div>
    </transition>
  </section>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import {
  eachWeekOfInterval,
  getMonth,
  lastDayOfMonth,
  startOfMonth,
  startOfWeek,
  getWeek,
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';

import DaterWeek from '@/components/calendar/DaterWeek.vue';

import icons from '@/mixins/icons';
import { nextBusinessDay } from '@/utils';

export default Vue.extend({
  name: 'Dater',
  components: {
    DaterWeek,
  },
  mixins: [icons],
  props: {
    showWeekend: {
      type: Boolean,
      default: false,
    },
    singleWeek: {
      type: Boolean,
      default: false,
    },
    toggleWeekly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['activeDate', 'direction', 'weeklyView']),
    daysOfWeek() {
      return [0, 1, 2, 3, 4, 5, 6].map((i) => {
        return enUS.localize.day((i + 1) % 7, { width: 'narrow' });
      });
    },
    monthName() {
      return enUS.localize.month(getMonth(this.activeDate));
    },
    year() {
      return this.activeDate.getFullYear();
    },
    weekly: {
      get() {
        return (
          this.$route.params.personId &&
          (this.$q.screen.gt.sm || this.weeklyView)
        );
      },
      set(value) {
        this.setWeeklyView(value);
      },
    },
    weekNumber() {
      return getWeek(this.activeDate);
    },
    weeks() {
      if (this.singleWeek) {
        return [startOfWeek(this.activeDate, { weekStartsOn: 1 })];
      }

      let start = startOfMonth(this.activeDate);
      const end = lastDayOfMonth(this.activeDate);

      // Skip the first week if it doesn't contain any business days
      if (!this.showWeekend) {
        start = nextBusinessDay(start);
      }

      return eachWeekOfInterval({ start, end }, { weekStartsOn: 1 });
    },
  },
  methods: {
    ...mapMutations(['setDateNext', 'setDatePrev', 'setWeeklyView']),
    swipe({ direction }) {
      this[`setDate${direction === 'right' ? 'Prev' : 'Next'}`](
        this.weeklyView && this.toggleWeekly ? 'week' : 'day',
      );
    },
  },
});
</script>

<style lang="scss">
.dater {
  box-shadow: 0 -2px 14px 0 rgba(0, 0, 0, 0.15);
  position: relative;

  @media screen and (min-width: $breakpoint-md-min) {
    margin-bottom: 0;
  }

  .week {
    justify-content: space-around;

    &:not(:last-child) {
      padding-bottom: map-get($space-xs, 'y');
    }

    .item {
      cursor: pointer;
    }
  }

  .item {
    font-size: 1.2em;
    height: 2.5em;
    text-align: center;
    width: 2.5em;
  }

  &.singleweek {
    .week {
      margin-left: calc(#{map-get($space-sm, 'x')} * -1.5);
      margin-right: calc(#{map-get($space-sm, 'x')} * -1.5);
    }

    .item {
      height: auto;
    }
  }
}
</style>
