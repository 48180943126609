import { Slot } from '@graphql/types';

type OneOf<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

type MixinTarget = Vue & OneOf<{ slot: Slot }>;

export default {
  computed: {
    percentage(this: MixinTarget) {
      return this.slot?.timing?.percentage || 0;
    },
  },
};
