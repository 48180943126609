<template>
  <div class="group q-pb-sm">
    <div class="title text-bold text-uppercase">
      <QSkeleton animation="blink" type="text" />
    </div>
    <div class="list">
      <QSkeleton
        v-for="(_, i) in Array(items)"
        :key="i"
        animation="blink"
        class="contact rounded-borders"
        height="175.6px"
        type="rect"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'GroupLoading',
  props: {
    items: {
      type: Number,
      default: 2,
    },
  },
});
</script>
