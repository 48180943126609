<template>
  <div :class="toolbarClasses">
    <Portal to="left" :disabled="$q.screen.lt.md">
      <div>
        <div
          class="filters flex no-wrap q-gutter-y-sm q-gutter-x-sm q-px-md q-pt-md"
        >
          <CompanyField
            :validate="false"
            :value="companyId"
            @input="setCompany"
          />
          <ProjectField
            v-if="company && $q.screen.lt.md"
            ref="project"
            :projects="projects"
            :validate="false"
            :value="project"
            @input="setProject"
          />
        </div>
        <div
          v-if="company && $q.screen.gt.sm"
          class="projectlist q-px-md q-py-lg"
        >
          <template v-if="projects.length">
            <router-link
              v-for="p in projects"
              :key="p.id"
              class="project block ellipsis"
              :class="{ 'text-grey-5': p.status === 'CHIU' }"
              :to="projectRoute(p)"
            >
              {{ p.id }} &ndash;
              {{ p.name }}
            </router-link>
          </template>
          <div v-else>No projects</div>
        </div>
      </div>
    </Portal>
  </div>
</template>

<script>
import Vue from 'vue';

import CompanyField from '@/components/forms/CompanyField.vue';
import ProjectField from '@/components/forms/ProjectField.vue';

import { Company } from '@graphql/queries/companies.gql';

import color from '@/mixins/color';

import { projectIdToParam, projectParamToId } from '@/utils';

export default Vue.extend({
  name: 'ProjectToolbar',
  apollo: {
    company: () => ({
      query: Company,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.companyId;
      },
      variables() {
        return {
          id: this.companyId,
        };
      },
    }),
  },
  components: {
    CompanyField,
    ProjectField,
  },
  mixins: [color],
  props: {
    companyId: {
      type: String,
      default: null,
    },
  },
  computed: {
    project() {
      return (this.projects || []).find(
        ({ id }) => this.projectId && id === projectParamToId(this.projectId),
      );
    },
    projectId() {
      return this.$route.params.projectId;
    },
    projects() {
      return (this.company?.projects || [])
        .sort((a, b) => {
          if (a.status === 'CHIU' && b.status !== 'CHIU') {
            return 1;
          } else if (a.status !== 'CHIU' && b.status === 'CHIU') {
            return -1;
          }

          return a.id.localeCompare(b.id);
        })
        .map((project) => ({ ...project, company: this.company }));
    },
    toolbarClasses() {
      return {
        toolbar: true,
        'q-px-md': this.$q.screen.gt.sm,
        'q-pt-md': this.$q.screen.gt.sm,
        'q-pb-sm': true,
      };
    },
  },
  methods: {
    setCompany(id) {
      this.$router.push({
        name: 'company_projects',
        params: { companyId: id },
      });

      if (this.$refs.project) {
        this.$refs.project.focus();
      }
    },
    projectRoute(project) {
      return {
        name: 'company_project',
        params: {
          companyId: this.companyId,
          projectId: projectIdToParam(project.id),
        },
      };
    },
    setProject(project) {
      this.$router.push(this.projectRoute(project));
    },
  },
});
</script>

<style lang="scss">
.projectlist .project {
  padding: $card-padding;
}
</style>
