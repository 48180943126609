import * as Sentry from '@sentry/browser';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  PersistentStorage,
  PersistedData,
} from 'apollo-cache-persist-dev/types';
import { openDB, DBSchema, IDBPTransaction } from 'idb';
import { AsyncStorage } from 'vuex-persist';

export let size = 10 * 1024 * 1024;

type Storage = AsyncStorage &
  PersistentStorage<PersistedData<NormalizedCacheObject>>;

let storage: Storage;

export async function init() {
  if (storage) {
    return storage;
  }

  if (window.matchMedia('(display-mode: standalone)').matches) {
    if (navigator.storage && navigator.storage.persist) {
      const persisted = await navigator.storage.persisted();

      if (!persisted) {
        const persistent = await navigator.storage.persist();

        Sentry.setExtra('persistentStorage', persistent);

        if (!persistent) {
          Sentry.captureMessage(
            'Could not persist storage',
            Sentry.Severity.Warning,
          );
        }
      }
    }
  }

  if (navigator.storage && navigator.storage.estimate) {
    const estimate = await navigator.storage.estimate();

    if (estimate.quota) {
      // use up to 50% quota for IndexedDB
      // leaving the rest for CacheStorage + SW
      size = Math.min(size, estimate.quota / 2);
    }
  }

  interface DBv2 extends DBSchema {
    keyvaluepairs: {
      key: string;
      value: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    };
  }

  interface DBv3 extends DBSchema {
    kv: {
      key: string;
      value: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    };
  }

  const db = openDB<DBv3>('rolodex', 3, {
    async upgrade(db, oldV, newV, tx) {
      let old;

      if (oldV == 2) {
        old = await ((tx as unknown) as IDBPTransaction<DBv2>)
          .objectStore('keyvaluepairs')
          .get('vuex');
      }

      for (const name of db.objectStoreNames) {
        db.deleteObjectStore(name);
      }

      const store = await db.createObjectStore('kv');

      if (old) {
        await store.put(old, 'vuex');
      }
    },
  });

  storage = {
    async getItem(key: string) {
      return (await db).get('kv', key);
    },
    async removeItem(key: string) {
      (await db).delete('kv', key);
    },
    async setItem<T>(key: string, value: T) {
      (await db).put('kv', value, key);
      return value;
    },
    async clear() {
      (await db).clear('kv');
    },
    async key(index) {
      let cursor = await (await db).transaction('kv').store.openCursor();

      if (!cursor) {
        return '';
      }

      if (index === 0) {
        return cursor.key;
      }

      cursor = await cursor.advance(index);
      if (!cursor) {
        return '';
      }

      return cursor.key;
    },
    async length() {
      return (await db).count('kv');
    },
  };

  return storage;
}
