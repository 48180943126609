<template>
  <QItem
    class="project--card"
    :class="backgroundClasses"
    :style="{ '--company-rgb': companyRGB }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <QItemSection>
      <QItemLabel v-if="company" class="text-bold company">
        {{ project.company.name }}
      </QItemLabel>
      <QItemLabel caption>{{ project.name }}</QItemLabel>
    </QItemSection>
    <QItemSection v-if="avatars" avatar>
      <div class="flex items-center q-gutter-x-sm">
        <Avatar :person="project.projectLeader" size="lg" />
        <Avatar :person="project.account" size="lg" />
      </div>
    </QItemSection>
  </QItem>
</template>

<script>
import Vue from 'vue';

import Avatar from '@/components/people/Avatar.vue';

import color from '@/mixins/color';

export default Vue.extend({
  name: 'Project',
  components: {
    Avatar,
  },
  mixins: [color],
  props: {
    avatars: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Boolean,
      default: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.project {
  &.q-item {
    min-height: 0;
  }
}
</style>
