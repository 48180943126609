import { render, staticRenderFns } from "./Settings.vue?vue&type=template&id=c170fc82&"
import script from "./Settings.vue?vue&type=script&lang=js&"
export * from "./Settings.vue?vue&type=script&lang=js&"
import style0 from "./Settings.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QScrollArea,QCard,QIcon,QCardSection,QImg,QCardActions,QBtn,QToggle});
