<template>
  <QSelect
    ref="select"
    v-bind="$attrs"
    label="Task *"
    :rules="[(val) => !!val || 'Field is required']"
    hide-bottom-space
    :hide-selected="hide"
    rounded
    stack-label
    standout
    use-input
    :options="options"
    :value="task"
    @filter="tasksFilter"
    @popup-show="hide = true"
    @popup-hide="hide = false"
    @input="onInput"
  >
    <template #selected-item="{ opt, itemProps, itemEvents }">
      <Task
        class="q-px-none q-pt-sm q-pb-xs"
        :task="opt.task"
        v-bind="itemProps"
        v-on="itemEvents"
      />
    </template>

    <template #option="{ opt, itemProps, itemEvents }">
      <Task
        :key="opt.task.id"
        :task="opt.task"
        v-bind="itemProps"
        v-on="itemEvents"
      />
    </template>

    <template #before-options>
      <QSeparator />
      <NewTaskOption @new-task="$emit('new-task', input)" />
    </template>

    <template #no-option>
      <NewTaskOption @new-task="$emit('new-task', input)" />
      <QItem>
        <QItemSection class="text-grey">No results</QItemSection>
      </QItem>
      <QSeparator />
    </template>
  </QSelect>
</template>

<script>
import Vue from 'vue';

import NewTaskOption from '@/components/forms/NewTaskOption.vue';
import Task from '@/components/tasks/Task.vue';

import { TaskList } from '@graphql/queries/tasks.gql';
import { Searcher } from '@/search';
import { taskKeys, taskOrders } from '@/utils';

function toOption(task) {
  return {
    value: task.id,
    task,
  };
}

export default Vue.extend({
  name: 'TaskField',
  apollo: {
    tasks: () => ({
      query: TaskList,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          filter: {
            open: true,
          },
        };
      },
    }),
  },
  components: {
    NewTaskOption,
    Task,
  },
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      input: '',
      hide: false,
      options: [],
    };
  },
  computed: {
    searcher() {
      return new Searcher(this.visibleTasks, taskKeys);
    },
    task() {
      const task = (this.tasks || []).find((task) => task.id === this.value);
      return task ? toOption(task) : undefined;
    },
    visibleTasks() {
      return [...(this.tasks || [])].sort(taskOrders['company_ASC'].compare);
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val.value);
      this.$refs.select.blur();
    },
    tasksFilter(val, update) {
      update(() => {
        this.input = val;

        if (val.length) {
          this.options = Object.freeze(
            this.searcher.search(val).map((r) => toOption(r.item)),
          );
        } else {
          this.options = Object.freeze(this.visibleTasks.map(toOption));
        }
      });
    },
  },
});
</script>
