<template>
  <QSelect
    ref="select"
    :label="`Company${validate ? ' *' : ''}`"
    :rules="validate ? [(val) => !!val || 'Field is required'] : []"
    hide-bottom-space
    :hide-selected="hide"
    rounded
    stack-label
    standout
    use-input
    :options="options"
    :value="company"
    @filter="companiesFilter"
    @popup-show="hide = true"
    @popup-hide="hide = false"
    @input="onInput"
  >
    <template #selected-item="{ opt, itemProps, itemEvents }">
      <Company
        class="q-px-none q-pt-sm q-pb-xs"
        :company="opt.company"
        v-bind="itemProps"
        v-on="itemEvents"
      />
    </template>

    <template #option="{ opt, itemProps, itemEvents }">
      <Company
        :key="opt.company.id"
        :company="opt.company"
        v-bind="itemProps"
        v-on="itemEvents"
      />
    </template>

    <template #no-option>
      <QItem>
        <QItemSection class="text-grey">No results</QItemSection>
      </QItem>
    </template>
  </QSelect>
</template>

<script>
import Vue from 'vue';

import Company from '@/components/projects/Company.vue';

import { Companies } from '@graphql/queries/companies.gql';

import { Searcher } from '@/search';
import { projectKeys } from '@/utils';

export function toOption(company) {
  return {
    company,
    value: company.id,
  };
}

export default Vue.extend({
  name: 'CompanyField',
  apollo: {
    allCompanies: () => ({
      query: Companies,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !!this.companies;
      },
      update(data) {
        return data.companies;
      },
    }),
  },
  components: {
    Company,
  },
  props: {
    companies: {
      type: Array,
      default: undefined,
    },
    validate: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      hide: false,
      options: [],
    };
  },
  computed: {
    company() {
      const company = (this.companies || this.allCompanies || []).find(
        (company) => company.id === this.value,
      );
      return company ? toOption(company) : undefined;
    },
    searcher() {
      return new Searcher(this.visibleCompanies, projectKeys);
    },
    visibleCompanies() {
      return [...(this.companies || this.allCompanies || [])];
    },
  },
  methods: {
    companiesFilter(val, update) {
      update(() => {
        if (!val.length) {
          this.options = Object.freeze(this.visibleCompanies.map(toOption));
        } else {
          this.options = Object.freeze(
            this.searcher.search(val).map((r) => toOption(r.item)),
          );
        }
      });
    },
    onInput(val) {
      this.$emit('input', val.value);
      this.$refs.select.blur();
    },
  },
});
</script>
