<template>
  <div class="app-state flex column justify-center items-center q-py-md">
    <div class="text-center text-body2 text-weight-bold text-uppercase">
      An error occurred
    </div>
    <div class="text-center">
      We're having some issues fetching data.
      <a class="retry-link" href="#" @click.prevent="$emit('retry')"
        >Click here to retry</a
      >.
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ErrorState',
  computed: {
    ...mapGetters(['working']),
  },
  watch: {
    working(working) {
      if (working) {
        this.$emit('retry');
      }
    },
  },
});
</script>
