<template>
  <div class="tasks--mobile">
    <template v-for="(task, i) of list">
      <HeaderMobile
        v-if="
          !list[i - 1] ||
          task.project.company.id !== list[i - 1].project.company.id
        "
        :key="`company-${task.id}`"
        :task="task"
      />
      <TaskMobile :key="`task-${task.id}`" :task="task" />
    </template>
  </div>
</template>

<script>
import Vue from 'vue';

import HeaderMobile from '@/components/tasks/HeaderMobile.vue';
import TaskMobile from '@/components/tasks/TaskMobile.vue';

export default Vue.extend({
  name: 'ListMobile',
  components: {
    HeaderMobile,
    TaskMobile,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
});
</script>
