<template>
  <div class="peoplelist q-px-md q-py-lg">
    <router-link
      v-for="person in people"
      :key="person.id"
      class="person flex items-center"
      :to="{
        name: to,
        params: { personId: person.id },
      }"
    >
      <Avatar :person="person" />
      <div class="ellipsis q-ml-md">
        {{ person.firstName }} {{ person.lastName }}
      </div>
    </router-link>
  </div>
</template>

<script>
import Vue from 'vue';

import Avatar from '@/components/people/Avatar.vue';

export default Vue.extend({
  name: 'PeopleList',
  components: {
    Avatar,
  },
  props: {
    people: {
      type: Array,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.peoplelist .person {
  padding: $card-padding;
}
</style>
