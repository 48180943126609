<template>
  <div
    class="column no-wrap rounded-borders"
    :class="classes"
    :data-task="task.id"
    :style="style"
    @click="openTask(task.id)"
  >
    <div>{{ task.project.name }}</div>
    <div class="text-body1 q-my-xs">
      {{ task.name }}
    </div>
    <div class="task__notes text-italic ellipsis-2-lines">
      {{ task.notes }}
    </div>
    <QSpace />
    <div class="task__extra flex no-wrap items-end justify-between">
      <div class="flex no-wrap">
        <Priority :value="task.priority" />
        <small
          v-if="task.delivery"
          class="text-bold"
          :class="{ 'text-negative': overdue }"
        >
          {{ humanStringDate(task.delivery) }}
        </small>
      </div>
      <QSpace />
      <div class="flex no-wrap items-end q-gutter-sm">
        <div class="projectid text-h6 text-weight-light">
          {{ task.project.id }}
        </div>
        <Avatar
          v-if="task.project.projectLeader"
          :person="task.project.projectLeader"
          size="md"
        />
        <Avatar
          v-if="task.project.account"
          :person="task.project.account"
          size="md"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import task from '@/mixins/task';

export default Vue.extend({
  name: 'TaskMobile',
  mixins: [task],
});
</script>
