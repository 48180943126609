<template>
  <QScrollArea class="column col-grow">
    <Toolbar :company-id="companyId" />

    <ErrorState v-if="error" @retry="refresh" />

    <div
      v-else-if="projectId && (!loading || updated)"
      :class="wrapperClasses"
      :style="{ '--company-rgb': companyRGB }"
    >
      <Header :project="project" />

      <QField
        v-if="project.timings.length"
        borderless
        class="q-px-md q-mt-md"
        label="Members"
        stack-label
      >
        <template #control>
          <Timing
            v-for="timing in project.timings"
            :key="timing.id"
            class="q-mr-xs q-mb-sm"
            show-numbers
            :timing="timing"
            size="xl"
          />
        </template>
      </QField>

      <div :class="contentClasses">
        <div
          v-for="(tasks, status) in taskStatuses"
          :key="status"
          class="col q-gutter-y-sm q-mb-lg"
        >
          <QItem
            class="status--card rounded-borders"
            :class="`status--${status}`"
          >
            <QItemSection>
              <QItemLabel class="text-weight-medium text-uppercase">
                {{ status }}
              </QItemLabel>
            </QItemSection>
            <QItemSection v-if="status !== 'closed'" avatar>
              <QBtn
                class="new-button"
                :class="{ active: activeStatus === status }"
                flat
                :icon="icon('plus')"
                round
                size="sm"
                @click="toggleNewTask(status)"
              />
            </QItemSection>
          </QItem>
          <Task
            v-for="task in tasks"
            :key="task.id"
            background
            clickable
            full
            :project="false"
            :task="{ ...task, project }"
            @click="setTask(task)"
          />

          <Form
            v-if="activeStatus === status"
            :project="project"
            :status="activeStatus"
          />

          <div v-else-if="tasks.length <= 0" class="task--card text-grey-5">
            No tasks
          </div>
        </div>
      </div>
    </div>
  </QScrollArea>
</template>

<script>
import Vue from 'vue';

import ErrorState from '@/components/layout/ErrorState.vue';
import Timing from '@/components/people/Timing.vue';
import Form from '@/components/projects/Form.vue';
import Header from '@/components/projects/Header.vue';
import Toolbar from '@/components/projects/Toolbar.vue';
import Task from '@/components/tasks/Task.vue';

import { Project } from '@graphql/queries/projects.gql';
import { Statuses } from '@graphql/queries/statuses.gql';
import {
  TaskCreated,
  TaskDeleted,
  TaskUpdated,
} from '@graphql/queries/tasks.gql';

import { EventBus } from '@/event-bus';

import color from '@/mixins/color';
import icons from '@/mixins/icons';

import { projectParamToId } from '@/utils.ts';

export default Vue.extend({
  name: 'Projects',
  apollo: {
    project: () => ({
      query: Project,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.projectId;
      },
      subscribeToMore: [
        {
          document: TaskCreated,
          updateQuery(data, { subscriptionData }) {
            const created = subscriptionData.data.taskCreated;

            if (created.project.id === data.project.id) {
              data.project.tasks = data.project.tasks.concat([created]);
            }

            return data;
          },
        },
        {
          document: TaskDeleted,
          updateQuery(data, { subscriptionData }) {
            const id = subscriptionData.data.taskDeleted;

            data.project.tasks = data.project.tasks.filter(
              (task) => task.id !== id,
            );

            return data;
          },
        },
        {
          document: TaskUpdated,
          updateQuery(data, { subscriptionData }) {
            const updated = subscriptionData.data.taskUpdated;

            data.project.tasks = data.project.tasks.map((task) =>
              task.id === updated.id ? updated : task,
            );

            return data;
          },
        },
      ],
      result(res) {
        this.error = res.error;
      },
      variables() {
        return {
          id: this.realProjectId,
        };
      },
    }),
    statuses: () => ({
      query: Statuses,
      fetchPolicy: 'cache-first',
    }),
  },
  components: {
    ErrorState,
    Form,
    Header,
    Task,
    Timing,
    Toolbar,
  },
  mixins: [color, icons],
  inject: ['openTask', 'closeForm'],
  props: {
    companyId: {
      type: String,
      default: undefined,
    },
    projectId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      activeStatus: null,
      error: null,
    };
  },
  computed: {
    contentClasses() {
      return {
        flex: this.$q.screen.gt.sm,
        'no-wrap': this.$q.screen.gt.sm,
        'q-gutter-lg': this.$q.screen.gt.sm,
        'q-mt-xl': true,
        'q-px-md': true,
      };
    },
    loading() {
      return this.$apolloData.loading;
    },
    maxHours() {
      return this.project.timings.reduce(
        (max, t) => Math.max(max, t.slot + t.timesheet),
        0,
      );
    },
    realProjectId() {
      return this.projectId && projectParamToId(this.projectId);
    },
    taskStatuses() {
      const statuses = {};
      for (const status of this.statuses.enumValues) {
        statuses[status.name] = [];
      }
      if (this.updated) {
        for (const task of this.project.tasks) {
          statuses[task.status].push(task);
        }
      }
      return statuses;
    },
    updated() {
      return (
        this.projectId && this.project?.id === projectParamToId(this.projectId)
      );
    },
    wrapperClasses() {
      return {
        board: true,
        rgb: true,
        'q-px-sm': this.$q.screen.lt.md,
        'q-px-md': this.$q.screen.gt.sm,
        'q-pb-xs': this.$q.screen.lt.md,
        'q-pb-sm': this.$q.screen.gt.sm,
        'q-pt-sm': this.$q.screen.lt.md,
      };
    },
  },
  watch: {
    projectId() {
      this.resetNewTask();
    },
  },
  created() {
    EventBus.$on('task-created', this.setTask);
  },
  beforeDestroy() {
    EventBus.$off('task-created', this.setTask);
  },
  methods: {
    refresh() {
      this.$apollo.queries.project.refetch();
    },
    resetNewTask() {
      this.activeStatus = null;
    },
    setTask(task) {
      this.resetNewTask();
      this.openTask(task.id);
    },
    toggleNewTask(status) {
      if (this.activeStatus === status) {
        this.resetNewTask();
      } else {
        this.closeForm();
        this.activeStatus = status;
      }
    },
  },
});
</script>

<style lang="scss">
.board {
  &__inner {
    max-width: calc(#{$default-max-width} - #{map-get($space-md, 'x')} * 2);
  }

  .q-gutter-lg {
    & > .q-gutter-y-sm {
      margin-top: -#{map-get($space-sm, 'y')};
    }
  }

  .new-button {
    transition: $generic-hover-transition;

    &.active {
      transform: rotate(45deg);
    }
  }

  @each $name, $color in $statuses {
    .status--#{$name} {
      background-color: $color;
      color: #fff;

      .body--dark & {
        background-color: rgba($color, 0.5);
        color: $dark;
      }
    }
  }
}
</style>
