<template>
  <QItem class="bg-grey-1 cursor-pointer">
    <QItemSection @click="$emit('new-task')">
      <QItemLabel class="text-bold"
        ><QBtn class="q-mr-md" color="grey" round flat :icon="icon('plus')" />
        <span class="text-bold">Create a new task</span>
      </QItemLabel>
    </QItemSection>
  </QItem>
</template>

<script>
import Vue from 'vue';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'NewTaskOption',
  mixins: [icons],
});
</script>
