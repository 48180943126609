<template>
  <QAvatar class="avatar" :class="{ 'avatar--empty': !img }" :size="size">
    <QImg v-if="img" basic :ratio="1" :src="img" />
    <slot v-else name="empty" :person="person">?</slot>
  </QAvatar>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Avatar',
  props: {
    size: {
      type: String,
      default: 'md',
    },
    person: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    img() {
      return this.person?.avatar?.replace(/_/g, '/')?.replace(/-/g, '+');
    },
  },
});
</script>
