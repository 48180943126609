<template>
  <tr
    class="cursor-pointer"
    :class="classes"
    :data-task="task.id"
    :style="style"
    @click="openTask(task.id)"
  >
    <td class="task__project">
      <div>{{ task.project.name }}</div>
      <div class="text-body1 q-mt-xs">
        {{ task.name }}
      </div>
    </td>
    <td>
      <Priority :value="task.priority" class="text-h6" />
    </td>
    <td class="text-bold">
      <span v-if="task.delivery" :class="{ 'text-negative': overdue }">
        {{ humanStringDate(task.delivery) }}
      </span>
    </td>
    <td>
      <div class="task__notes text-italic ellipsis-3-lines">
        {{ task.notes }}
      </div>
    </td>
    <td>
      <div class="flex no-wrap justify-end q-gutter-sm">
        <Avatar
          v-if="task.project.projectLeader"
          :person="task.project.projectLeader"
          size="lg"
        />
        <Avatar
          v-if="task.project.account"
          :person="task.project.account"
          size="lg"
        />
      </div>
    </td>
    <td class="text-right">
      <span class="projectid text-h6 text-weight-light">
        {{ task.project.id }}
      </span>
    </td>
    <td class="task__status">
      <div class="vertical text-uppercase text-weight-medium">{{ status }}</div>
    </td>
  </tr>
</template>

<script>
import Vue from 'vue';

import task from '@/mixins/task';

export default Vue.extend({
  name: 'TaskDesktop',
  mixins: [task],
});
</script>
