<template>
  <div class="task__header--mobile text-bold" :style="style">
    {{ task.project.company.name }}
  </div>
</template>

<script>
import Vue from 'vue';

import task from '@/mixins/task';

export default Vue.extend({
  name: 'HeaderMobile',
  mixins: [task],
});
</script>
