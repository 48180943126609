<template>
  <div class="week flex">
    <div
      v-for="(d, i) in days"
      :key="i"
      :class="dayClasses(d)"
      @click="setActiveDate(d)"
    >
      <div v-if="$q.screen.lt.md">{{ weekDay(i) }}</div>
      <div>{{ d.getDate() }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import enUS from 'date-fns/locale/en-US';
import {
  eachDayOfInterval,
  isSameDay,
  isSameMonth,
  isWeekend,
  setDay,
  toDate,
} from 'date-fns';

export default Vue.extend({
  name: 'DaterWeek',
  props: {
    showWeekend: {
      type: Boolean,
      default: false,
    },
    week: {
      type: Date,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['activeDate']),
    days() {
      return eachDayOfInterval({
        start: this.week,
        end: setDay(toDate(this.week), this.showWeekend ? 0 : 5, {
          weekStartsOn: 1,
        }),
      });
    },
  },
  methods: {
    ...mapMutations(['setActiveDate']),
    dayClasses(date) {
      return {
        item: true,
        selected: isSameDay(date, this.activeDate),
        today: isSameDay(date, new Date()),
        inactive: isWeekend(date) || !isSameMonth(date, this.activeDate),
        'q-pa-sm': true,
      };
    },
    weekDay(dateIndex) {
      return enUS.localize.day((dateIndex + 1) % 7, { width: 'narrow' });
    },
  },
});
</script>
