import { register } from 'register-service-worker';
import { init as initStore } from '@/store';

export function init() {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  register(`/service-worker.js`, {
    async registered(reg) {
      (await initStore()).commit('setSWReg', reg);
    },
    async updatefound() {
      (await initStore()).commit('setUpdating');
    },
    async offline() {
      (await initStore()).commit('setOffline', true);
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.error('Error during service worker registration:', error);
    },
  });
}
