<template>
  <QCircularProgress
    class="timing"
    :class="{ over: timing.percentage > 1 }"
    show-value
    :size="size"
    :style="{ '--over': timing.total - timing.budget }"
    :thickness="0.2"
    :title="title"
    track-color="grey-3"
    :value="timing.percentage * 100"
  >
    <Avatar :person="timing.person" :size="avatar" />
    <div v-if="showNumbers" style="margin-bottom: -2.5em">
      {{ Math.round(timing.total) }}h
    </div>
  </QCircularProgress>
</template>

<script>
import Vue from 'vue';

import Avatar from '@/components/people/Avatar.vue';

export default Vue.extend({
  name: 'Timing',
  components: {
    Avatar,
  },
  props: {
    showNumbers: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'xl',
    },
    timing: {
      type: Object,
      required: true,
    },
  },
  computed: {
    avatar() {
      return {
        lg: '30px',
        xl: '38px',
      }[this.size];
    },
    title() {
      if (!this.showNumbers) {
        return;
      }

      return `${Math.round(this.timing.total)}h / ${Math.round(
        this.timing.budget,
      )}h`;
    },
  },
});
</script>

<style lang="scss">
.q-circular-progress.timing {
  color: var(--color);
}
</style>
