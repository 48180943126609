<template>
  <section :class="classes" :style="style">
    <div :class="innerClasses" @click="openSlot(slot.id)">
      <div class="flex no-wrap items-baseline">
        <div class="ellipsis">
          <strong class="company">
            {{ slot.task.project.company.name }}
          </strong>
          <span v-if="!full" class="q-ml-xs">
            {{ slot.task.project.name }}
          </span>
        </div>
        <QSpace />
        <small class="duration">{{ slot.duration }}h</small>
      </div>
      <div v-if="full" class="ellipsis q-mb-sm">
        {{ slot.task.project.name }}
      </div>
      <div :class="{ 'text-body1': full, 'ellipsis-2-lines': !full }">
        {{ slot.task.name }}
      </div>

      <QSpace />

      <div class="flex no-wrap items-baseline q-gutter-xs">
        <Priority :value="slot.task.priority" />
        <small v-if="slot.task.delivery" class="text-bold">
          {{ humanStringDate(slot.task.delivery) }}
        </small>
        <QSpace />
        <div v-if="full" class="projectid text-h6 text-weight-light">
          {{ slot.task.project.id }}
        </div>
        <Avatar
          v-if="full && slot.task.project.projectLeader"
          :person="slot.task.project.projectLeader"
          size="md"
        />
        <Avatar
          v-if="full && slot.task.project.account"
          :person="slot.task.project.account"
          size="md"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';

import Avatar from '@/components/people/Avatar.vue';
import Priority from '@/components/tasks/Priority.vue';

import color from '@/mixins/color';
import date from '@/mixins/date';
import timing from '@/mixins/timing';

export default Vue.extend({
  name: 'Booking',
  components: {
    Avatar,
    Priority,
  },
  mixins: [color, date, timing],
  inject: ['openSlot'],
  props: {
    booking: {
      type: Object,
      required: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        slot: true,
        'slot--selected': this.slot.id === this.$route.query.slotId,
        [`duration-${this.slot.duration}`]: true,
      };
    },
    innerClasses() {
      return [
        'slot__inner',
        'rounded-borders',
        'cursor-pointer',
        'column',
        'no-wrap',
        'justify-between',
      ];
    },
    slot() {
      return this.booking;
    },
    style() {
      return {
        '--company-rgb': this.companyRGB,
        '--percentage': this.percentage,
      };
    },
  },
});
</script>
