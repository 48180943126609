<template>
  <div :style="{ '--company-rgb': companyRGB }">
    <QChip
      v-if="timing.budget <= 0 && timing.timesheet > 0"
      class="rgb--background"
      :icon="icon('history')"
    >
      <span class="q-mx-xs text-caption">
        Unplanned: {{ Math.round(timing.timesheet) }}h
      </span>
    </QChip>

    <QChip v-else class="rgb--background" :icon="icon('history')">
      <span class="q-mx-xs text-caption">
        {{ Math.round(timing.timesheet) }}h / {{ Math.round(timing.budget) }}h
      </span>
    </QChip>

    <QChip
      v-if="timing.slot > 0"
      class="rgb--background"
      :icon="icon('update')"
    >
      <span class="q-mx-xs text-caption">Scheduled: {{ timing.slot }}h</span>
    </QChip>
  </div>
</template>

<script>
import Vue from 'vue';

import color from '@/mixins/color';
import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'TimingDetails',
  mixins: [color, icons],
  props: {
    company: {
      type: Object,
      required: true,
    },
    timing: {
      type: Object,
      required: true,
    },
  },
});
</script>
