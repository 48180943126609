<template>
  <QInput
    ref="inner"
    v-bind="$attrs"
    maxlength="60"
    no-error-icon
    rounded
    :rules="[(val) => !!val || 'Field is required']"
    standout
    v-on="$listeners"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </QInput>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'NameField',
  methods: {
    validate() {
      return this.$refs.inner.validate();
    },
  },
});
</script>
