<template>
  <QLayout
    :view="$q.screen.gt.sm ? 'hHh LpR fFf' : 'hhh LpR fFf'"
    class="text-montserrat"
  >
    <Header />

    <QDrawer
      v-if="$q.screen.gt.sm && content('left')"
      elevated
      no-swipe-close
      persistent
      side="left"
      :value="true"
    >
      <QScrollArea class="fit">
        <PortalTarget name="left" slim />
      </QScrollArea>
    </QDrawer>

    <QPageContainer>
      <QPage class="column">
        <router-view />
      </QPage>
    </QPageContainer>

    <AutoForm />

    <Footer v-if="$q.screen.lt.md" />
  </QLayout>
</template>

<script>
import Vue from 'vue';
import { Wormhole } from 'portal-vue';

import AutoForm from '@/components/layout/AutoForm.vue';
import Footer from '@/components/layout/Footer.vue';
import Header from '@/components/layout/Header.vue';

import { EventBus } from '@/event-bus';
import { init as initStore } from '@/store';

export default Vue.extend({
  name: 'App',
  components: {
    AutoForm,
    Footer,
    Header,
  },
  provide() {
    return {
      closeForm: this.closeForm,
      openSlot: this.openSlot,
      openTask: this.openTask,
    };
  },
  computed: {
    baseRoute() {
      // eslint-disable-next-line no-unused-vars
      const { date, personId, slotId, taskId, ...query } = this.$route.query;
      return {
        ...this.$route,
        query,
      };
    },
    content() {
      return (name) => Wormhole.hasContentFor(name);
    },
  },
  watch: {
    '$q.dark.isActive': {
      handler(val) {
        const theme = document.querySelector('meta[name="theme-color"]');
        theme?.setAttribute('content', val ? '#1e2025' : '#616e7e');
      },
      immediate: true,
    },
  },
  async created() {
    const store = await initStore();
    this.unwatch = store.watch(
      (state) => state.darkMode,
      (darkMode) => {
        this.$q.dark.set(darkMode ? 'auto' : false);
      },
      { immediate: true },
    );

    EventBus.$on('slot-deleted', this.closeForm);
    EventBus.$on('task-deleted', this.closeForm);
    EventBus.$on('task-updated', this.back);
  },
  beforeDestroy() {
    this.unwatch();

    EventBus.$off('slot-deleted', this.closeForm);
    EventBus.$off('task-deleted', this.closeForm);
    EventBus.$off('task-updated', this.back);
  },
  methods: {
    back() {
      history.back();
    },
    closeForm() {
      this.$router.push(this.baseRoute);
    },
    openSlot(id, query = {}) {
      const base = this.baseRoute;
      const method = this.$route.query.slotId === 'new' ? 'replace' : 'push';

      this.$router[method]({
        ...base,
        query: {
          ...base.old,
          ...query,
          slotId: id,
        },
      });
    },
    openTask(id) {
      const base = this.baseRoute;
      this.$router.push({
        ...base,
        query: {
          ...base.old,
          taskId: id,
        },
      });
    },
  },
});
</script>

<style lang="scss">
.q-page-container {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
</style>
