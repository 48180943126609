import { logout } from '@/auth';
import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { CachePersistor } from 'apollo-cache-persist-dev';
import { split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { SentryLink } from 'apollo-link-sentry';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import Vue from 'vue';
import VueApollo from 'vue-apollo';

import { DAY_DURATION } from '@graphql/config.ts';
import { Schedule } from '@graphql/types';
import { init as initStorage, size } from '@/storage';
import { init as initStore } from '@/store';

Vue.use(VueApollo);

let persistor: CachePersistor<NormalizedCacheObject>;

export async function createProvider() {
  const store = await initStore();

  const httpLink = createHttpLink({
    uri: '/graphql',
  });

  const subscriptionClient = new SubscriptionClient(
    `ws${location.protocol.replace('http', '')}//${location.host}/graphql`,
    {
      connectionParams: () => ({
        token: store.getters.token,
      }),
      lazy: true,
      reconnect: true,
    },
  );

  subscriptionClient.onConnected(() => {
    store.commit('setConnected', true);
  });

  subscriptionClient.onReconnected(() => {
    store.commit('setConnected', true);
  });

  subscriptionClient.onDisconnected(() => {
    store.commit('setConnected', false);
  });

  const wsLink = new WebSocketLink(subscriptionClient);

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const authLink = setContext((_, { headers }) => {
    const token = store.getters.token;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : undefined,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors?.find((e) => e.extensions?.code === 'UNAUTHENTICATED')) {
      logout();
    }
  });

  const retryLink = new RetryLink({
    delay: {
      max: 3000,
    },
  });

  const sentryLink = new SentryLink();

  const cache = new InMemoryCache();
  persistor = new CachePersistor<NormalizedCacheObject>({
    cache,
    storage: await initStorage(),
    maxSize: (size * 4) / 5,
    serialize: false,
  });

  await persistor.restore();

  const client = new ApolloClient({
    link: authLink
      .concat(retryLink)
      .concat(errorLink)
      .concat(sentryLink)
      .concat(link),
    cache,
    connectToDevTools: true,
    resolvers: {
      Schedule: {
        free(schedule: Schedule) {
          return schedule.slots.reduce(
            (total, slot) => total - slot.duration,
            DAY_DURATION,
          );
        },
      },
    },
  });

  const apolloProvider = new VueApollo({
    defaultClient: client,
  });

  return apolloProvider;
}

export function purgeCache() {
  if (persistor) {
    return persistor.purge();
  }

  return true;
}
