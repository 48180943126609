<template>
  <div class="app-state flex column justify-center items-center q-py-md">
    <QImg :src="emptyState.src" :ratio="448 / 300" />
    <div class="q-mt-md text-center text-body2 text-weight-bold text-uppercase">
      Nobody has planned anything yet
    </div>
    <div class="text-center">{{ emptyState.copy }}</div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'EmptyState',
  computed: {
    emptyState() {
      return this.emptyStates[
        Math.floor(Math.random() * this.emptyStates.length)
      ];
    },
    emptyStates() {
      return [
        {
          id: 'totem',
          copy: 'No activities on the horizon',
        },
        {
          id: 'dreamcatcher',
          copy: "We're catching new activities",
        },
        {
          id: 'tent',
          copy: "We're hunting for new activities",
        },
      ].map(({ id, copy }) => ({
        id,
        copy,
        src: require(`@/assets/empty-states/${id}.png`),
      }));
    },
  },
});
</script>
