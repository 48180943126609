<template>
  <QTabs
    class="navigation"
    indicator-color="transparent"
    no-caps
    shrink
    stretch
  >
    <QRouteTab to="/calendar" v-bind="props('Calendar')" />
    <QRouteTab to="/tasks" v-bind="props('Tasks')" />
    <QRouteTab to="/contacts" v-bind="props('Contacts')" />
    <QRouteTab to="/passwords" v-bind="props('Passwords')" />
    <QRouteTab to="/nas" v-bind="props('Files')" />
  </QTabs>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Menu',
  computed: {
    props() {
      return (name) => {
        const iconName = this.$q.screen.gt.sm ? `${name}-w` : name;
        const icon = require(`@/assets/icons/${iconName.toLowerCase()}.svg`);
        return {
          icon: `img:${icon}`,
          label: this.$q.screen.gt.sm ? name : undefined,
        };
      };
    },
  },
});
</script>

<style lang="scss">
.navigation {
  .q-tabs__arrow {
    display: none;
  }

  .q-tab__content {
    opacity: 0.25;
  }

  .q-tab--active .q-tab__content {
    opacity: 1;
  }
}
</style>
