<template>
  <div class="group q-pb-sm">
    <div :id="id" class="title text-bold text-uppercase">
      {{ name }}
    </div>
    <div class="list">
      <component
        :is="item"
        v-for="each in items"
        :key="each.id"
        v-bind="{ [prop]: each }"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Group',
  props: {
    id: {
      type: String,
      required: true,
    },
    item: {
      type: Function,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    prop: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.group {
  .title {
    padding: $header-padding;
    padding-bottom: map-get($space-sm, 'y');
  }

  .list {
    align-items: flex-start;
    display: grid;
    grid-auto-flow: dense;
    grid-auto-rows: min-content;
    grid-gap: map-get($space-sm, 'x');
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  }
}
</style>
