import Vue from 'vue';
import Router from 'vue-router';

import { exchange, loggedIn, login, logout } from '@/auth';
import { init as initStore } from '@/store';

import Calendar from '@/views/Calendar.vue';
import Contacts from '@/views/Contacts.vue';
import Projects from '@/views/Projects.vue';
import Settings from '@/views/Settings.vue';
import Tasks from '@/views/Tasks.vue';

Vue.use(Router);

let router: Router;

export async function init() {
  if (router) {
    return router;
  }

  const store = await initStore();

  router = new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else if (!to.meta.keepScroll && !from.meta.keepScroll) {
        return { x: 0, y: 0 };
      }
    },
    routes: [
      {
        path: '/',
        name: 'home',
        redirect() {
          const name = store.getters.homeScreen;
          const personId = store.getters.username;

          if (name.endsWith('_person')) {
            return {
              name,
              params: {
                personId,
              },
            };
          }

          return { name };
        },
      },
      {
        path: '/calendar',
        name: 'calendar',
        component: Calendar,
        props: true,
        children: [
          {
            path: 'person/:personId',
            name: 'calendar_person',
          },
        ],
      },
      {
        path: '/tasks',
        name: 'tasks',
        component: Tasks,
        props: true,
        children: [
          {
            path: 'person/:personId',
            name: 'tasks_person',
          },
        ],
      },
      {
        path: '/contacts',
        name: 'contacts',
        component: Contacts,
      },
      {
        path: '/passwords',
        name: 'passwords',
        beforeEnter(to, from, next) {
          window.open(
            'https://docs.google.com/spreadsheets/d/1_0IKMq8eDpUYrez-6FL_noXB4ZQDhorYkg8zy17i0p4',
          );
          next(false);
        },
      },
      {
        path: '/projects',
        name: 'projects',
        component: Projects,
        props: true,
        children: [
          {
            path: ':companyId',
            name: 'company_projects',
            children: [
              {
                path: ':projectId',
                name: 'company_project',
              },
            ],
          },
        ],
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
      },
      {
        path: '/login',
        name: 'login',
        async beforeEnter(to, from, next) {
          const { access_token, state } = Object.fromEntries(
            new URLSearchParams(to.hash.substr(1)),
          );

          if (access_token) {
            await exchange(access_token);
          }

          if (!(await loggedIn())) {
            return login(to.params.path);
          }

          return next(state || '/');
        },
        meta: {
          requiresLogin: false,
        },
      },
      {
        path: '/logout',
        name: 'logout',
        async beforeEnter() {
          await logout();
        },
      },
      {
        path: '/nas',
        name: 'nas',
        beforeEnter(to, from, next) {
          window.open('https://files.tribe.srl');
          next(false);
        },
      },
    ],
  });

  router.beforeEach(async (to, _, next) => {
    if (to.meta.requiresLogin === false || (await loggedIn())) {
      return next();
    }

    next({
      name: 'login',
      params: {
        path: to.fullPath,
      },
    });
  });

  router.beforeEach((to, from, next) => {
    const p1 = from.path.split('/')[1];
    const p2 = to.path.split('/')[1];

    if (p1 != p2 && store.getters.query) {
      store.commit('setQuery', null);
    }

    next();
  });

  router.beforeEach(async (to, _, next) => {
    if (!store.getters.hasPendingForm) {
      return next();
    }

    store.getters.pendingForm(next);
  });

  window.addEventListener('beforeunload', (e) => {
    if (!store.getters.hasPendingForm) {
      return;
    }

    e.preventDefault();
    e.returnValue = '';
  });

  return router;
}
