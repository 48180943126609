<template>
  <div class="toolbar toolbar--calendar">
    <Portal to="left" :disabled="$q.screen.lt.md">
      <div>
        <Dater
          :single-week="$q.screen.lt.md"
          :toggle-weekly="person && $q.screen.lt.md"
        />
        <PeopleList
          v-if="$q.screen.gt.sm"
          :people="people"
          to="calendar_person"
        />
      </div>
    </Portal>

    <div :class="innerClasses">
      <div class="toolbar__inner">
        <div v-if="!person" class="toolbar__text">
          {{ weekDay }}, {{ month }} <span class="text-bold">{{ day }}</span
          ><span v-if="$q.screen.gt.xs">, {{ year }} </span>
        </div>
        <PersonBack v-else :person="person" :to="{ name: 'calendar' }" />

        <QSpace />
        <Today />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { format } from 'date-fns';
import { mapGetters } from 'vuex';

import Dater from '@/components/calendar/Dater.vue';
import Today from '@/components/calendar/Today.vue';
import PeopleList from '@/components/people/PeopleList.vue';
import PersonBack from '@/components/people/PersonBack.vue';

export default Vue.extend({
  name: 'CalendarToolbar',
  components: {
    Dater,
    PeopleList,
    PersonBack,
    Today,
  },
  props: {
    people: {
      type: Array,
      required: true,
    },
    person: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters(['activeDate']),
    day() {
      return format(this.activeDate, 'do');
    },
    month() {
      return format(this.activeDate, 'LLLL');
    },
    weekDay() {
      return format(this.activeDate, this.$q.screen.gt.xs ? 'EEEE' : 'EEE');
    },
    innerClasses() {
      return {
        'q-px-md': true,
        'q-py-md': true,
        'q-pl-person': !this.person,
      };
    },
    year() {
      return format(this.activeDate, 'yyyy');
    },
  },
});
</script>
