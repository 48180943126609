<template>
  <QScrollArea class="column col-grow">
    <div class="row q-mx-auto" style="max-width: 1440px">
      <div class="col-sm-1 col-md-3" />
      <div class="q-px-md q-pb-md col-12 col-sm-10 col-md-6">
        <div class="q-my-xl">
          <div class="text-center text-h6">
            <span style="vertical-align: text-middle">{{ emoji }}</span>
            <span v-if="currentUser" class="text-uppercase text-weight-bold">
              {{ currentUser.firstName }}!
            </span>
          </div>

          <div class="text-center text-h6 text-weight-light">
            Choose your home screen:
          </div>
        </div>

        <div class="row items-center q-col-gutter-md">
          <div
            v-for="option of options"
            :key="option.id"
            :class="optionClasses(option)"
          >
            <QCard bordered flat>
              <div :class="cardClasses(option)">
                <QIcon
                  v-if="option.icon"
                  color="grey-5"
                  :name="option.icon"
                  size="sm"
                  class="absolute-left q-mt-md q-ml-md"
                />
                {{ option.name }}
              </div>
              <QCardSection v-bind="sectionProps" class="justify-center">
                <div
                  :class="[
                    $q.screen.gt.xs ? 'q-mx-auto q-px-sm' : 'q-pa-md q-mb-sm',
                  ]"
                  style="width: 200px"
                >
                  <QImg
                    :src="option.active ? option.srcActive : option.src"
                    :ratio="200 / 120"
                  />
                </div>
                <QCardActions
                  align="center"
                  :class="[$q.screen.gt.xs ? 'q-mt-md' : 'col-3']"
                >
                  <QBtn
                    :color="option.active ? 'primary' : 'white'"
                    :text-color="option.active ? 'white' : 'primary'"
                    v-bind="{ outline: !option.active }"
                    unelevated
                    @click="setHomeScreen(option.id)"
                  >
                    {{ option.active ? 'Active' : 'Set' }}
                  </QBtn>
                </QCardActions>
              </QCardSection>
            </QCard>
          </div>
        </div>

        <div class="q-my-xl">
          <div class="text-center text-h6 text-weight-light">
            Dangerous experiments:
          </div>
        </div>

        <div class="q-col-gutter-md">
          <QToggle v-model="dark" label="Enable automatic dark mode" />
        </div>

        <div class="text-center q-my-lg">
          <QBtn color="primary" :to="{ name: 'home' }">Save</QBtn>
        </div>
      </div>
      <div class="col-sm-1 col-md-3" />
    </div>
  </QScrollArea>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';

import { CurrentUser } from '@graphql/queries/people.gql';

import icons from '@/mixins/icons';

export default Vue.extend({
  name: 'Settings',
  apollo: {
    currentUser: () => ({
      query: CurrentUser,
      fetchPolicy: 'cache-and-network',
    }),
  },
  mixins: [icons],
  computed: {
    ...mapGetters(['darkMode', 'homeScreen']),
    dark: {
      get() {
        return this.darkMode;
      },
      set(value) {
        this.setDarkMode(!!value);
      },
    },
    emoji() {
      const list = [
        '👋',
        '🤙',
        '🤘',
        '🤟',
        '🖖',
        '👌',
        '✌',
        '🤞',
        '👉',
        '👍',
        '🤜',
        '👏',
        '🙌',
        '🤝',
        '🙏',
        '💪',
      ];

      return list[Math.floor(Math.random() * list.length)];
    },
    options() {
      return [
        {
          id: 'calendar',
          name: 'Calendar overview',
        },
        {
          id: 'calendar_person',
          name: 'My calendar',
          icon: this.icon('accountOutline'),
        },
        {
          id: 'tasks',
          name: 'All tasks',
        },
        {
          id: 'tasks_person',
          name: 'My tasks',
          icon: this.icon('accountOutline'),
        },
      ].map(({ id, name, icon }) => ({
        id,
        name,
        icon,
        src: require(`@/assets/pages/${id}.png`),
        srcActive: require(`@/assets/pages/${id}_active.png`),
        active: id === this.homeScreen,
      }));
    },
    sectionProps() {
      return {
        horizontal: this.$q.screen.lt.sm,
      };
    },
  },
  methods: {
    ...mapMutations(['setDarkMode', 'setHomeScreen']),
    cardClasses(option) {
      return [
        'q-mt-md',
        'text-primary',
        'text-center',
        'text-body1',
        'text-uppercase',
        option.active ? 'text-weight-bold' : 'text-weight-regular',
      ];
    },
    optionClasses(option) {
      return [
        'col-xs-12',
        'col-sm-6',
        'option',
        option.active && 'option--active',
      ];
    },
  },
});
</script>

<style lang="scss">
.option {
  .q-card {
    border-radius: $default-border-radius;
  }

  &--active .q-card {
    background-color: $option-active-color;
    border-color: $primary;
    border-width: 2px;
  }
}
</style>
